import { useContext } from 'react';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { ProgramCtaContainer } from '../ProgramCtaContainer';
import { GroupProgramGuideMembershipRequiresApproval } from '../GroupProgramGuideMembershipRequiresApproval';
import { GroupProgramGuideMembershipPublished } from '../GroupProgramGuideMembershipPublished';
import { GroupProgramGuideMembershipUnpublished } from '../GroupProgramGuideMembershipUnpublished';
import { GroupProgramGuideScheduleSession } from '../GroupProgramGuideScheduleSession';
import { NotificationHighlightsContainer } from '../NotificationHighlightsContainer';

export function GroupProgramGuide() {
  const {
    membership,
    hasMemberships,
    hasSessions,
    hasAttendedSessions,
    hasCompletedOnboarding,
    isHiddenGroup,
    isActiveGroup,
    isEmptyGroup,
  } = useContext(DashboardNotificationsContext);
  const isNotActiveGroup = !isActiveGroup;
  const isNotEmptyGroup = !isEmptyGroup;

  if (hasCompletedOnboarding) {
    return <NotificationHighlightsContainer />;
  }

  if (!hasMemberships) {
    return <ProgramCtaContainer />;
  }

  // group guide remains at this step until at least one trainee joins group and group is activated
  if (isEmptyGroup || (isNotEmptyGroup && isNotActiveGroup)) {
    if (membership?.requiresApproval) {
      return <GroupProgramGuideMembershipRequiresApproval />;
    } else if (isHiddenGroup) {
      return <GroupProgramGuideMembershipUnpublished />;
    } else {
      return <GroupProgramGuideMembershipPublished />;
    }
  }

  // group guide can schedule a session once group is activated
  if (isActiveGroup && !hasSessions) {
    return <GroupProgramGuideScheduleSession />;
  }

  if (!hasAttendedSessions) {
    return <NotificationHighlightsContainer />;
  }
}
