import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Groups from '@mui/icons-material/Groups';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { DashboardOnboardingView } from 'views/dashboard';
import { PrimaryPanelUserAvatar } from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';

export function GroupProgramGuideScheduleSession() {
  const navigate = useNavigate();
  const { programName, relationshipId } = useContext(
    DashboardNotificationsContext,
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const {
    title,
    description,
    schedule_group_session_button_label: primaryButtonLabel,
  } = baseLanguage.dashboard.onboarding.steps.group_program
    .guide_schedule_group_session;

  const onPrimaryButtonClick = () => {
    navigate(`/relationships/${relationshipId}/sessions/create`);
  };

  return (
    <DashboardOnboardingView
      avatarA={<PrimaryPanelUserAvatar />}
      avatarB={<Groups />}
      programLabel={programName}
      heading={title}
      description={description}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
    />
  );
}
