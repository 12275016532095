import { useEffect, useCallback, useRef, useState } from 'react';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { Loading } from '@guider-global/ui';
import { useNotifications } from 'hooks/useNotifications';
import { useModalNotifications } from 'hooks/useNotificationsModal';
import { NotificationModalView } from 'views/dashboard/NotificationModalView';
import { NotificationCardContainer } from '../notificationCardContainer';
import { INotification } from '@guider-global/shared-types';

export type NotificationModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export function NotificationModalContainer({
  open,
  onClose,
}: Readonly<NotificationModalContainerProps>) {
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const {
    getModalNotifications,
    reqModalNotifications,
    getIsLoadingModalNotifications,
    getMore: reqMoreModalNotifications,
    hasMore: checkHasMoreModalNotifications,
  } = useModalNotifications({
    getSilently: true,
    isPaginated: true,
    pageLimit: 10,
  });
  const modalNotifications = getModalNotifications();
  const isLoadingModalNotifications = getIsLoadingModalNotifications();
  const hasMoreModalNotifications = checkHasMoreModalNotifications();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const [didUpdateNotifications, setUpdatedNotifications] =
    useState<boolean>(false);
  useEffect(() => {
    if (!open) return;
    if (isLoadingModalNotifications) return;
    if (didUpdateNotifications) return;
    setUpdatedNotifications(true);
    reqModalNotifications({
      method: 'PATCH',
      url: '/notifications',
      params: {},
      data: { status: 'read' },
    });
  }, [
    open,
    isLoadingModalNotifications,
    didUpdateNotifications,
    reqModalNotifications,
  ]);

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
    };
    const handleObserver: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];
      if (
        entry.isIntersecting &&
        hasMoreModalNotifications &&
        !isLoadingModalNotifications
      ) {
        reqMoreModalNotifications({});
      }
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [
    hasMoreModalNotifications,
    isLoadingModalNotifications,
    reqMoreModalNotifications,
  ]);

  const headingText = baseLanguage.dashboard.notifications.modal.title;
  const closeButtonLabel =
    baseLanguage.globals?.common?.close_button_label ?? 'Close';

  return (
    <NotificationModalView
      open={open}
      headingText={headingText}
      closeButtonLabel={closeButtonLabel}
      onClose={handleClose}
    >
      {modalNotifications.map((notification, index, array) => {
        const isFifthLastNofication = index === array.length - 5;
        return (
          <>
            {isFifthLastNofication && (
              <div ref={loaderRef} style={{ margin: 0 }} />
            )}
            <NotificationCardContainer
              key={notification.id}
              notification={notification}
            />
          </>
        );
      })}
      <Loading
        isLoading={isLoadingModalNotifications}
        color="white"
        sx={{ padding: '2%' }}
      />
    </NotificationModalView>
  );
}
