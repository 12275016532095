import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { OnboardingChecklistView } from 'views';
import {
  SecondaryPanel,
  SecondaryPanelSkeleton,
  SecondaryPanelTitle,
} from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';

export function OnboardingChecklistContainer() {
  const {
    hasMemberships,
    hasRelationships: contextHasRelationships,
    hasSessions,
    hasAttendedSessions,
    programSlug,
    relationshipId,
    sessionId,
    isGuide,
    isGroupProgram,
    isActiveGroup,
    isEmptyGroup,
    isLoading,
  } = useContext(DashboardNotificationsContext);

  const navigate = useNavigate();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const isNotEmptyGroup = !isEmptyGroup;
  const isNotActiveGroup = !isActiveGroup;
  const hasRelationships =
    isGroupProgram && isGuide ? isNotEmptyGroup : contextHasRelationships;

  if (isLoading) {
    return <SecondaryPanelSkeleton />;
  }

  return (
    <SecondaryPanel>
      <SecondaryPanelTitle
        text={baseLanguage.dashboard.onboarding.progress_indicator.title}
      />
      <OnboardingChecklistView
        heading={baseLanguage.dashboard.onboarding.progress_indicator.title}
        percentCompleteLabel={
          baseLanguage.dashboard.onboarding.progress_indicator.complete_label
        }
        progressDescription={
          baseLanguage.dashboard.onboarding.progress_indicator.complete_label
        }
        description={
          baseLanguage.dashboard.onboarding.progress_indicator.description
        }
        steps={[
          {
            key: 'join-program',
            label:
              baseLanguage.dashboard.onboarding.progress_indicator.steps
                .join_a_program_label,
            percentCompletion: 25,
            isChecked: hasMemberships,
            onClick: () => navigate('/programs'),
            isCurrent:
              !hasMemberships &&
              !hasRelationships &&
              !hasSessions &&
              !hasAttendedSessions,
          },
          {
            key: 'find-match',
            label:
              baseLanguage.dashboard.onboarding.progress_indicator.steps
                .find_a_match_label,
            percentCompletion: 25,
            isChecked: hasRelationships,
            onClick: () =>
              navigate(`/programs/${programSlug}/join/trainee/choose`),
            isCurrent:
              hasMemberships &&
              (isGroupProgram && isGuide
                ? isNotActiveGroup // group guide remains at this step until group is activated
                : !hasRelationships) &&
              !hasSessions &&
              !hasAttendedSessions,
          },
          {
            key: 'schedule-session',
            label:
              baseLanguage.dashboard.onboarding.progress_indicator.steps
                .schedule_session_label,
            isChecked: hasSessions,
            percentCompletion: 25,
            onClick: () =>
              navigate(`/relationships/${relationshipId}/sessions/create`),
            isCurrent:
              hasMemberships &&
              (isGroupProgram && isGuide
                ? isActiveGroup // group guide can schedule a session once group is activated
                : hasRelationships) &&
              !hasSessions &&
              !hasAttendedSessions,
          },
          {
            key: 'attend-session',
            label:
              baseLanguage.dashboard.onboarding.progress_indicator.steps
                .attend_session_label,
            percentCompletion: 25,
            isChecked: hasAttendedSessions,
            onClick: () =>
              navigate(
                `/relationships/${relationshipId}/sessions/${sessionId}`,
              ),
            isCurrent:
              hasMemberships &&
              hasRelationships &&
              hasSessions &&
              !hasAttendedSessions,
          },
        ]}
      />
    </SecondaryPanel>
  );
}
