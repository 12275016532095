import { GoalStatus } from '@guider-global/shared-types';
import { AlertColor } from '@mui/material';

export type GetGoalStatusColorParams = {
  status: GoalStatus;
};

export function getGoalStatusColor({
  status,
}: GetGoalStatusColorParams): AlertColor {
  switch (status) {
    case 'on_track':
      return 'success';
    case 'on_hold':
      return 'warning';
    case 'off_track':
      return 'error';
    case 'completed':
      return 'success';
    default:
      return 'info';
  }
}
