import { useContext, useEffect, useState } from 'react';
import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import { ISession } from '@guider-global/shared-types';
import { GroupAvatar, SurveyModal } from '@guider-global/ui';
import { SurveyForm } from 'forms';
import {
  useRelationships,
  useSessions,
  useSurveyResources,
  useSurveys,
  useUsers,
} from 'hooks';
import { SurveyAvatar } from 'components';
import { SurveyModalsContext } from '../SurveyModalsContext';
import { isValidDate } from '@guider-global/front-end-utils';
import { isSurveyFieldForGuide, isSurveyFieldForTrainee } from 'functions';

export function PostSessionSurveyModalContainer() {
  const { surveyType, setSurveyType, isSurveySubmitted } =
    useContext(SurveyModalsContext);

  // Local state
  const [elapsedSession, setElapsedSession] = useState<ISession>();

  // Sanity organization programs
  const { getPrograms, isLoadingSanityPrograms: getIsLoadingSanityPrograms } =
    useSanityOrganizationPrograms({});
  const isLoadingSanityPrograms = getIsLoadingSanityPrograms();
  const programs = getPrograms();

  // Users
  const { users: getUsers, isLoadingUsers: getIsLoadingUsers } = useUsers({});
  const isLoadingUsers = getIsLoadingUsers();
  const [user] = getUsers();

  // Relationships
  const {
    relationships: getRelationships,
    isLoadingRelationships: getIsLoadingRelationships,
  } = useRelationships({});
  const isLoadingRelationships = getIsLoadingRelationships();
  const relationships = getRelationships();
  const relationship = relationships.find(
    (relationship) => relationship.id === elapsedSession?.relationshipId,
  );
  const programSlug = relationship?.programSlug;

  // Sessions
  const { sessions: getSessions, isLoadingSessions: getIsLoadingSessions } =
    useSessions({});
  const isLoadingSessions = getIsLoadingSessions();
  const sessions = getSessions();

  // Surveys
  const { getSurveys, getIsLoadingSurveys } = useSurveys({});
  const isLoadingSurveys = getIsLoadingSurveys();
  const surveys = getSurveys();

  const isLoadingPostSessionSurveyResources =
    isLoadingSanityPrograms ||
    isLoadingRelationships ||
    isLoadingUsers ||
    isLoadingSessions ||
    isLoadingSurveys;

  useEffect(() => {
    if (isLoadingPostSessionSurveyResources) {
      return;
    }

    const now = new Date();

    const sessionWithoutSurvey = sessions.find((session) => {
      const sessionEndDate = new Date(session.end);
      const hasSessionElapsed = sessionEndDate < now;
      const relationship = relationships.find(
        (relationship) => relationship.id === session.relationshipId,
      );
      const isGuide = relationship?.guideProfiles?.at(0)?.userId === user?.id;
      const sessionCount = relationship?.sessions?.length ?? 0;

      const program = programs.find(
        (program) => program.metadata.id.current === session.programSlug,
      );
      const survey = program?.surveys?.post_session_survey;
      const surveyFields = survey?.fields ?? [];
      const surveyFieldsByRole = surveyFields.filter((surveyField) =>
        isGuide
          ? isSurveyFieldForGuide(surveyField, sessionCount)
          : isSurveyFieldForTrainee(surveyField, sessionCount),
      );
      const surveyStartDate = survey?.start_date;

      const hasSessionEndedAfterSurveyStartDate =
        surveyStartDate &&
        isValidDate(surveyStartDate) &&
        sessionEndDate > new Date(surveyStartDate);
      const isNotPastSession = !session.pastSession;
      const hasNotProvidedSessionFeedback = !surveys.find(
        (survey) =>
          survey.type === 'post-session' && survey.session === session.id,
      );

      return (
        survey?.enabled &&
        surveyFieldsByRole.length &&
        hasSessionElapsed &&
        relationship &&
        hasSessionEndedAfterSurveyStartDate &&
        hasNotProvidedSessionFeedback &&
        isNotPastSession
      );
    });

    setElapsedSession(sessionWithoutSurvey);
  }, [
    isLoadingPostSessionSurveyResources,
    relationships,
    user?.id,
    sessions,
    programs,
    surveys,
  ]);

  useEffect(() => {
    if (
      !isLoadingSurveys &&
      !isSurveySubmitted &&
      !surveyType &&
      elapsedSession
    ) {
      setSurveyType('post-session');
    }
  }, [
    isLoadingSurveys,
    isSurveySubmitted,
    surveyType,
    elapsedSession,
    setSurveyType,
  ]);

  const {
    title,
    heading,
    subheading,
    profile,
    surveyFields,
    isGuide,
    isGroupProgram,
    isSurveyEnabled,
    isOnGuiderVideoPage,
  } = useSurveyResources({
    surveyType: 'post_session_survey',
    relationship,
    user,
  });

  if (
    isSurveySubmitted ||
    surveyType !== 'post-session' ||
    !surveyType ||
    !programSlug ||
    !isSurveyEnabled ||
    !elapsedSession ||
    !surveyFields.length ||
    isOnGuiderVideoPage
  ) {
    return <></>;
  }

  return (
    <SurveyModal
      open
      title={title}
      heading={heading}
      subheading={subheading}
      Avatar={
        isGroupProgram ? (
          <GroupAvatar width={64} height={64} size="48px" />
        ) : (
          <SurveyAvatar
            relationshipId={elapsedSession.relationshipId}
            isGuide={isGuide}
          />
        )
      }
    >
      <SurveyForm
        title={title}
        programSlug={programSlug}
        profile={profile?.id}
        type={'post-session'}
        session={elapsedSession.id}
        surveyFields={surveyFields}
        isGuide={isGuide}
      />
    </SurveyModal>
  );
}
