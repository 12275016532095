import { usePictureMulti } from '@guider-global/azure-storage-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { LeaderboardRow } from '@guider-global/ui';
import { useLeaderboardPositions, useProfiles, useUsers } from 'hooks';

export function useProgressLeaderboardResources() {
  const organizationSlug = getSubDomain();
  const { settings } = useSanitySettings({});
  const defaultAvatarSrc = buildSanityImageUrl({
    source: settings?.static_media.relationships.default_user_avatar ?? '',
  });

  const { users: getUsers, isLoadingUsers: getIsLoadingUsers } = useUsers({
    getSilently: false,
  });
  const isLoadingUsers = getIsLoadingUsers();
  const userSelf = getUsers().at(0);
  const userIdSelf = userSelf?.id;

  const { getProfiles, isLoadingProfiles: getIsLoadingProfiles } = useProfiles({
    getSilently: false,
  });
  const isLoadingProfiles = getIsLoadingProfiles();
  const ownProfile = userIdSelf
    ? getProfiles().find((profile) => profile.userId === userIdSelf)
    : undefined;
  const ownProfileId = ownProfile?.id;

  const {
    leaderboardPositions: getLeaderboardPositions,
    isLoadingLeaderboardPositions: getIsLoadingLeaderboardPositions,
  } = useLeaderboardPositions({
    organizationSlug,
    getSilently: true,
    getSilentlyUrl: '/leaderboardPositions',
  });
  const isLoadingLeaderboardPositions = getIsLoadingLeaderboardPositions();
  const leaderboardPositions = getLeaderboardPositions();
  const ownLeaderboardPosition = ownProfileId
    ? leaderboardPositions.find(
        (leaderboardPosition) => leaderboardPosition.id === ownProfileId,
      )
    : undefined;
  const ownLeaderboardPositionIndex = ownProfileId
    ? leaderboardPositions.findIndex(
        (leaderboardPosition) => leaderboardPosition.id === ownProfileId,
      )
    : undefined;

  const sessionCount = ownLeaderboardPosition?.sessionCount ?? 0;
  const relationshipCount = ownLeaderboardPosition?.relationshipCount ?? 0;
  const goalCount = ownLeaderboardPosition?.goalCount ?? 0;
  const contributions = ownLeaderboardPosition?.contributions ?? 0;

  const pictureParamsList = leaderboardPositions.map((position) => ({
    pictureId: position.id,
    sasTokenApiPath: '/admin/storage',
    containerName: 'pictures',
    pictureBlobName: position.avatarSrc,
  }));
  const { picture: avatarSrcs } = usePictureMulti({ pictureParamsList });

  const leaderboardRows = leaderboardPositions.map<LeaderboardRow>(
    (leaderboardPosition, index) => {
      const position = index + 1;
      const { displayName, contributions: score } = leaderboardPosition;
      return {
        position,
        avatarSrc: avatarSrcs[leaderboardPosition.id] ?? defaultAvatarSrc,
        displayName,
        score,
      };
    },
  );

  const isLoadingProgressLeaderboardResources =
    isLoadingUsers || isLoadingProfiles || isLoadingLeaderboardPositions;

  return {
    userIdSelf,
    ownProfileId,
    leaderboardPositions,
    ownLeaderboardPosition,
    ownLeaderboardPositionIndex,
    sessionCount,
    relationshipCount,
    goalCount,
    contributions,
    avatarSrcs,
    leaderboardRows,
    isLoadingProgressLeaderboardResources,
  };
}

export type ProgressLeaderboardResources = ReturnType<
  typeof useProgressLeaderboardResources
>;
