import { useContext } from 'react';
import { Loading } from '@guider-global/ui';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { NotificationPanelView } from 'views/dashboard/NotificationPanelView';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { GroupProgramGuide } from '../GroupProgramGuide';
import { IndividualProgramGuide } from '../IndividualProgramGuide';
import { GroupProgramTrainee } from '../GroupProgramTrainee';
import { IndividualProgramTrainee } from '../IndividualProgramTrainee';

export function PrimaryPanelContainer() {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const { isGuide, isGroupProgram, isLoading } = useContext(
    DashboardNotificationsContext,
  );
  const isIndividualProgram = !isGroupProgram;
  const isTrainee = !isGuide;

  let component: JSX.Element | undefined = undefined;

  if (isLoading) {
    component = <Loading sx={{ height: '100%', color: 'white' }} />;
  } else if (isGroupProgram && isGuide) {
    component = <GroupProgramGuide />;
  } else if (isGroupProgram && isTrainee) {
    component = <GroupProgramTrainee />;
  } else if (isIndividualProgram && isGuide) {
    component = <IndividualProgramGuide />;
  } else if (isIndividualProgram && isTrainee) {
    component = <IndividualProgramTrainee />;
  }

  return (
    <NotificationPanelView
      title={baseLanguage.dashboard.notifications.panel.title}
    >
      {component}
    </NotificationPanelView>
  );
}
