import { Box as MuiBox } from '@mui/material';
import { DashboardNotificationsProvider } from '../DashboardNotificationsContext';
import { PrimaryPanelContainer } from '../PrimaryPanelContainer';
import { SecondaryPanelContainer } from '../SecondaryPanelContainer';

export function DashboardNotificationsContainer() {
  return (
    <DashboardNotificationsProvider>
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'stretch',
          gap: 2,
          mt: -4,
        }}
      >
        <PrimaryPanelContainer />
        <SecondaryPanelContainer />
      </MuiBox>
    </DashboardNotificationsProvider>
  );
}
