import {
  GoalStatus,
  SanityBaseLanguageGoals,
} from '@guider-global/shared-types';

export type GetGoalStatusTextParams = {
  status: GoalStatus;
  baseLanguageGoals: SanityBaseLanguageGoals;
};

export function getGoalStatusText({
  status,
  baseLanguageGoals,
}: GetGoalStatusTextParams) {
  switch (status) {
    case 'on_track':
      return baseLanguageGoals.goal_statuses.on_track;
    case 'on_hold':
      return baseLanguageGoals.goal_statuses.on_hold;
    case 'off_track':
      return baseLanguageGoals.goal_statuses.off_track;
    case 'completed':
      return baseLanguageGoals.goal_statuses.completed;
    default:
      return 'N/A';
  }
}
