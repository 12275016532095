import { useContext } from 'react';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { ProgressLeaderboardContainer } from '../progressLeaderboardContainer';
import { OnboardingChecklistContainer } from '../onboardingChecklistContainer';
import { SecondaryPanelSkeleton } from 'components/Dashboard';

export function SecondaryPanelContainer() {
  const { hasCompletedOnboarding, isLoading } = useContext(
    DashboardNotificationsContext,
  );

  return isLoading ? (
    <SecondaryPanelSkeleton />
  ) : hasCompletedOnboarding ? (
    <ProgressLeaderboardContainer />
  ) : (
    <OnboardingChecklistContainer />
  );
}
