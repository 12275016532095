import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { SurveyType } from '@guider-global/shared-types';

export interface SurveyModalsContextProps {
  surveyType?: SurveyType;
  setSurveyType: (surveyType: SurveyType) => void;
  isSurveySubmitted: boolean;
  setIsSurveySubmitted: (isSurveySubmitted: boolean) => void;
  resetState: () => void;
}

export const SurveyModalsContext = createContext<SurveyModalsContextProps>({
  surveyType: undefined,
  setSurveyType: () => {
    throw new Error('setSurveyType is not implemented');
  },
  isSurveySubmitted: false,
  setIsSurveySubmitted: () => {
    throw new Error('setIsSurveySubmitted is not implemented');
  },
  resetState: () => {
    throw new Error('resetState is not implemented');
  },
});

export function SurveyModalsProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const [surveyType, setSurveyType] = useState<SurveyType>();
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);

  const resetState = useCallback(() => {
    setSurveyType(undefined);
    setIsSurveySubmitted(false);
  }, [setSurveyType, setIsSurveySubmitted]);

  const value = useMemo(
    () => ({
      surveyType,
      setSurveyType,
      isSurveySubmitted,
      setIsSurveySubmitted,
      resetState,
    }),
    [
      surveyType,
      setSurveyType,
      isSurveySubmitted,
      setIsSurveySubmitted,
      resetState,
    ],
  );

  return (
    <SurveyModalsContext.Provider value={value}>
      {children}
    </SurveyModalsContext.Provider>
  );
}
