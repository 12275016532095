import { PropsWithChildren, createContext, useMemo } from 'react';
import { useMemberships, useRelationships, useSessions } from 'hooks';
import { compareDesc } from 'date-fns';
import {
  EProgramVariation,
  IMembership,
  IRelationship,
  Program,
} from '@guider-global/shared-types';
import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';

export interface DashboardNotificationsContextProps {
  membership?: IMembership;
  relationship?: IRelationship;
  hasMemberships: boolean;
  hasRelationships: boolean;
  hasSessions: boolean;
  hasAttendedSessions: boolean;
  hasCompletedOnboarding: boolean;
  program?: Program;
  programSlug: string;
  programName: string;
  relationshipId: string;
  sessionId: string;
  isGuide: boolean;
  isGroupProgram: boolean;
  isHiddenGroup: boolean;
  isActiveGroup: boolean;
  isEmptyGroup: boolean;
  isLoading: boolean;
}

export const DashboardNotificationsContext =
  createContext<DashboardNotificationsContextProps>({
    membership: undefined,
    relationship: undefined,
    hasMemberships: false,
    hasRelationships: false,
    hasSessions: false,
    hasAttendedSessions: false,
    hasCompletedOnboarding: false,
    program: undefined,
    programSlug: '',
    programName: '',
    relationshipId: '',
    sessionId: '',
    isGuide: false,
    isGroupProgram: false,
    isHiddenGroup: false,
    isActiveGroup: false,
    isEmptyGroup: false,
    isLoading: false,
  });

export function DashboardNotificationsProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const { getProgram } = useSanityOrganizationPrograms({});

  const {
    memberships: getMemberships,
    isLoadingMemberships: getIsLoadingMemberships,
  } = useMemberships({ getSilently: false });
  const isLoadingMemberships = getIsLoadingMemberships();
  const memberships = getMemberships();
  const [membership] = memberships;
  const hasMemberships = memberships.length > 0;
  const programSlug = membership?.programSlug ?? '';
  const program = getProgram(programSlug);
  const programName = program?.metadata.program_name ?? '';

  const isGuide = membership?.role === 'guide';

  const {
    relationships: getRelationships,
    isLoadingRelationships: getIsLoadingRelationships,
  } = useRelationships({ getSilently: false });
  const isLoadingRelationships = getIsLoadingRelationships();
  const relationships = getRelationships();
  const [relationship] = relationships;
  const relationshipId = relationship?.id ?? '';
  const hasRelationships = relationships.length > 0;

  const isGroupProgram =
    program?.program_details?.program_variation === EProgramVariation.Group;
  const isHiddenGroup = membership?.isPublished === false;
  const isActiveGroup = isHiddenGroup; // if group is hidden, it means it bas been activated by the guide
  const isEmptyGroup = relationship?.traineeProfiles?.length === 0;

  const { sessions: getSessions, isLoadingSessions: getIsLoadingSessions } =
    useSessions({ getSilently: true });
  const isLoadingSessions = getIsLoadingSessions();
  const sessions = getSessions();
  const [session] = sessions;
  const sessionId = session?.id ?? '';
  const hasSessions = sessions.length > 0;

  const attendedSessions = sessions.filter(
    (session) => compareDesc(new Date(session.start), new Date()) === 1,
  );
  const hasAttendedSessions = attendedSessions.length > 0;
  const hasCompletedOnboarding = hasAttendedSessions; // if user has attended a session, it means they have completed onboarding

  const isLoading =
    isLoadingMemberships || isLoadingRelationships || isLoadingSessions;

  const value = useMemo(
    () => ({
      membership,
      relationship,
      hasMemberships,
      hasRelationships,
      hasSessions,
      hasAttendedSessions,
      hasCompletedOnboarding,
      program,
      programSlug,
      programName,
      relationshipId,
      sessionId,
      isGuide,
      isGroupProgram,
      isHiddenGroup,
      isActiveGroup,
      isEmptyGroup,
      isLoading,
    }),
    [
      membership,
      relationship,
      hasMemberships,
      hasRelationships,
      hasSessions,
      hasAttendedSessions,
      hasCompletedOnboarding,
      program,
      programSlug,
      programName,
      relationshipId,
      sessionId,
      isGuide,
      isGroupProgram,
      isHiddenGroup,
      isActiveGroup,
      isEmptyGroup,
      isLoading,
    ],
  );

  return (
    <DashboardNotificationsContext.Provider value={value}>
      {children}
    </DashboardNotificationsContext.Provider>
  );
}
