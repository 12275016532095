import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ManageSearch from '@mui/icons-material/ManageSearch';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { DashboardOnboardingView } from 'views/dashboard';
import { PrimaryPanelUserAvatar } from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';

export function IndividualProgramGuideMembershipRequiresApproval() {
  const navigate = useNavigate();
  const { programSlug, programName } = useContext(
    DashboardNotificationsContext,
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const primaryButtonLabel =
    baseLanguage.globals?.common?.explore_learning_hub ??
    'Explore Learning Hub';

  const secondaryButtonLabel =
    baseLanguage.globals?.common?.edit_profile_button_label ?? 'Edit Profile';

  const {
    title,
    description,
    edit_profile_button_description: secondaryButtonCaption,
  } = baseLanguage.dashboard.onboarding.steps.individual_program
    .guide_membership_pending_approval;

  const editProfileButtonLabel =
    baseLanguage.globals?.common?.edit_profile_button_label ?? 'Edit Profile';

  const onPrimaryButtonClick = () => {
    navigate('/learn');
  };

  const onSecondaryButtonClick = () => {
    navigate(`/programs/${programSlug}/manage/guide`);
  };

  return (
    <DashboardOnboardingView
      avatarA={<PrimaryPanelUserAvatar />}
      avatarB={<ManageSearch />}
      programLabel={programName}
      heading={title}
      description={description}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonLabel={editProfileButtonLabel}
      secondaryButtonCaption={secondaryButtonCaption}
      onSecondaryButtonClick={onSecondaryButtonClick}
    />
  );
}
