import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Groups from '@mui/icons-material/Groups';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { DashboardOnboardingView } from 'views/dashboard';
import { PrimaryPanelUserAvatar } from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { interpolate } from 'functions';

export function GroupProgramGuideMembershipPublished() {
  const navigate = useNavigate();
  const { relationship, program, programSlug, programName, relationshipId } =
    useContext(DashboardNotificationsContext);
  const maxSpots = program?.relationships.group_settings?.max_trainees ?? 0;
  const spotsOccupied = relationship?.traineeProfiles?.length ?? 0;
  const spotsLeft = maxSpots - spotsOccupied;

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const {
    title,
    description,
    edit_group_profile_button_label: secondaryButtonLabel,
    view_group_button_label: primaryButtonLabel,
  } = baseLanguage.dashboard.onboarding.steps.group_program
    .guide_activate_group;

  const onPrimaryButtonClick = () => {
    navigate(`/relationships/${relationshipId}`);
  };

  const onSecondaryButtonClick = () => {
    navigate(`/programs/${programSlug}/manage/guide`);
  };

  return (
    <DashboardOnboardingView
      avatarA={<PrimaryPanelUserAvatar />}
      avatarB={<Groups />}
      programLabel={programName}
      heading={title}
      description={interpolate(description, {
        groupMembers: spotsOccupied.toString(),
        spotsLeft: spotsLeft.toString(),
        maxSpots: maxSpots.toString(),
      })}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonLabel={secondaryButtonLabel}
      onSecondaryButtonClick={onSecondaryButtonClick}
    />
  );
}
