import { getAuthorizedProgramsList } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { ICustomField } from '@guider-global/shared-types';
import { useProfiles } from 'hooks';
import { useNavigate } from 'react-router-dom';
import {
  ProgramCtaProgram,
  ProgramCtaView,
} from 'views/dashboard/ProgramCtaView';

export function ProgramCtaContainer() {
  const navigate = useNavigate();

  // Profiles
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const profileOrganizationFields =
    (profile?.organizationFields as ICustomField[] | undefined) ?? [];

  // Programs
  const { getPrograms } = useSanityOrganizationPrograms({});
  const programs = getPrograms();
  const authorizedPrograms = getAuthorizedProgramsList({
    programs,
    profileOrganizationFields,
  });
  const ctaPrograms: ProgramCtaProgram[] = authorizedPrograms.map(
    (authorizedProgram) => {
      const name = authorizedProgram.metadata.program_name;
      const description =
        authorizedProgram.program_details?.program_description;
      const image = authorizedProgram.program_details
        ? buildSanityImageUrl({
            source: authorizedProgram?.program_details?.program_image,
            width: 600,
          })
        : undefined;
      const id = authorizedProgram?.metadata?.id?.current;
      const displayPreference =
        authorizedProgram?.program_details?.display_preference;
      const type =
        authorizedProgram.program_details?.program_type?.program_type_text
          ?.common?.verb;
      const onClick = () => navigate(`/programs/${id}`);
      return {
        name,
        description,
        image,
        type,
        displayPreference,
        onClick,
      };
    },
  );

  return <ProgramCtaView programs={ctaPrograms} />;
}
