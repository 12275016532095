import {
  ProgressLeaderboardCardView,
  ProgressLeaderboardModalView,
} from '../../../views';

import { useState } from 'react';
import { useProgressLeaderboardResources } from './useProgressLeaderboardResources';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  SecondaryPanel,
  SecondaryPanelSkeleton,
  SecondaryPanelTitle,
} from '../../../components/Dashboard';

export function ProgressLeaderboardContainer() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const organizationSlug = getSubDomain();

  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const showLeaderboard = !!getOrganization()?.dashboard?.leaderboard;

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const {
    sessionCount,
    relationshipCount,
    goalCount,
    contributions,
    leaderboardRows,
    ownLeaderboardPositionIndex,
    isLoadingProgressLeaderboardResources,
  } = useProgressLeaderboardResources();

  function handleOpenModal() {
    setShowModal(true);
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  const keyStats = [
    {
      key: 'sessions',
      label: baseLanguage?.globals?.sessions?.sessions ?? 'Sessions',
      value: sessionCount,
    },
    {
      key: 'relationships',
      label:
        baseLanguage?.globals?.relationships?.relationships ?? 'Relationships',
      value: relationshipCount,
    },
    {
      key: 'goals',
      label: baseLanguage?.globals?.goals?.goals ?? 'Goals',
      value: goalCount,
    },
  ];

  if (isLoadingProgressLeaderboardResources) {
    return <SecondaryPanelSkeleton />;
  }

  return (
    <SecondaryPanel>
      <SecondaryPanelTitle
        text={baseLanguage?.dashboard?.leaderboard?.card.title}
      />
      <ProgressLeaderboardCardView
        keyStats={keyStats}
        dialMetricLabel={`${contributions}`}
        dialMetricDescription={
          baseLanguage?.dashboard?.leaderboard?.counts?.contributions_label
        }
        leaderboardHeading={
          baseLanguage?.dashboard?.leaderboard?.card?.leaderboard_title
        }
        leaderboardRows={leaderboardRows}
        selectedLeaderboardRow={ownLeaderboardPositionIndex}
        viewLeaderboardLabel={
          baseLanguage?.dashboard?.leaderboard?.card
            ?.view_leaderboard_button_label
        }
        onViewLeaderboard={handleOpenModal}
        showLeaderboard={showLeaderboard}
      />
      <ProgressLeaderboardModalView
        open={showModal}
        onClose={handleCloseModal}
        heading={baseLanguage?.dashboard?.leaderboard?.modal?.title}
        yourContributionsLabel={
          baseLanguage?.dashboard?.leaderboard?.modal?.your_contributions_label
        }
        dialMetricLabel={`${contributions}`}
        dialMetricDescription={
          baseLanguage?.dashboard?.leaderboard?.counts?.contributions_label
        }
        keyStats={keyStats}
        leaderboardRows={leaderboardRows}
        selectedLeaderboardRow={ownLeaderboardPositionIndex}
        rankColumnLabel={
          baseLanguage?.dashboard?.leaderboard?.modal?.table?.rank_column_label
        }
        profileColumnLabel={
          baseLanguage?.dashboard?.leaderboard?.modal?.table?.name_column_label
        }
        contributionsColumnLabel={
          baseLanguage?.dashboard?.leaderboard?.modal?.table
            ?.contributions_column_label
        }
      />
    </SecondaryPanel>
  );
}
