import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonOff from '@mui/icons-material/PersonOff';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { DashboardOnboardingView } from 'views/dashboard';
import { PrimaryPanelUserAvatar } from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';

export function GroupProgramGuideMembershipUnpublished() {
  const navigate = useNavigate();
  const { programName, programSlug } = useContext(
    DashboardNotificationsContext,
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const {
    title,
    description,
    edit_group_profile_button_label: secondaryButtonLabel,
    publish_profile_button_label: primaryButtonLabel,
    edit_group_profile_button_description: secondaryButtonCaption,
  } = baseLanguage.dashboard.onboarding.steps.group_program
    .guide_group_membership_hidden;

  const onPrimaryButtonClick = () => {
    navigate(`/programs/${programSlug}/manage/guide`);
  };

  const onSecondaryButtonClick = () => {
    navigate(`/programs/${programSlug}/manage/guide`);
  };

  return (
    <DashboardOnboardingView
      avatarA={<PrimaryPanelUserAvatar />}
      avatarB={<PersonOff />}
      programLabel={programName}
      heading={title}
      description={description}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonCaption={secondaryButtonCaption}
      onSecondaryButtonClick={onSecondaryButtonClick}
    />
  );
}
