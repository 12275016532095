import { useContext } from 'react';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { ProgramCtaContainer } from '../ProgramCtaContainer';
import { GroupProgramTraineeMembershipPublished } from '../GroupProgramTraineeMembershipPublished';
import { GroupProgramTraineeWaitForSession } from '../GroupProgramTraineeWaitForSession';
import { NotificationHighlightsContainer } from '../NotificationHighlightsContainer';

export function GroupProgramTrainee() {
  const {
    hasMemberships,
    hasRelationships,
    hasSessions,
    hasAttendedSessions,
    hasCompletedOnboarding,
  } = useContext(DashboardNotificationsContext);

  if (hasCompletedOnboarding) {
    return <NotificationHighlightsContainer />;
  }

  if (!hasMemberships) {
    return <ProgramCtaContainer />;
  }

  if (!hasRelationships) {
    return <GroupProgramTraineeMembershipPublished />;
  }

  if (!hasSessions) {
    return <GroupProgramTraineeWaitForSession />;
  }

  if (!hasAttendedSessions) {
    return <NotificationHighlightsContainer />;
  }
}
