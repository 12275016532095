import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Groups from '@mui/icons-material/Groups';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { DashboardOnboardingView } from 'views/dashboard';
import { PrimaryPanelUserAvatar } from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';

export function GroupProgramTraineeMembershipPublished() {
  const navigate = useNavigate();
  const { programSlug, programName } = useContext(
    DashboardNotificationsContext,
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const {
    title,
    description,
    select_a_group_button_label: primaryButtonLabel,
    edit_profile_button_description: secondaryButtonCaption,
  } = baseLanguage.dashboard.onboarding.steps.group_program
    .trainee_select_a_group;

  const onPrimaryButtonClick = () => {
    navigate(`/programs/${programSlug}/join/trainee/choose`);
  };

  const onSecondaryButtonClick = () => {
    navigate(`/programs/${programSlug}/join/trainee`);
  };

  return (
    <DashboardOnboardingView
      avatarA={<PrimaryPanelUserAvatar />}
      avatarB={<Groups />}
      programLabel={programName}
      heading={title}
      description={description}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonLabel={
        baseLanguage.globals.common.edit_profile_button_label
      }
      secondaryButtonCaption={secondaryButtonCaption}
      onSecondaryButtonClick={onSecondaryButtonClick}
    />
  );
}
