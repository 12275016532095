import {
  UsePictureParams,
  usePictureMulti,
} from '@guider-global/azure-storage-hooks';
import { getLanguageFromCMS } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { INotification } from '@guider-global/shared-types';
import {
  NotificationBubbleView,
  NotificationCardView,
} from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { interpolate } from 'functions';
import { useEffect, useRef } from 'react';
import { getSessionDateAndDuration } from 'utils/getSessionDateAndDuration';
import { NotificationBubbleDateLabel } from './NotificationBubbleDateLabel';
import { getGoalStatusColor } from './getGoalStatusColor';
import { getGoalStatusText } from './getGoalStatusText';
import { parseNotificationMetaData } from './parseNotificationMetaData';

export type NotificationCardContainerProps = {
  notification: INotification;
  onVisible?: () => void;
};

export function NotificationCardContainer({
  notification,
  onVisible,
}: Readonly<NotificationCardContainerProps>) {
  const cardRef = useRef<HTMLDivElement | null>(null);

  // Sanity
  const { getPrograms } = useSanityOrganizationPrograms({});
  const programs = getPrograms();
  const { settings } = useSanitySettings({});
  const defaultAvatar = buildSanityImageUrl({
    source: settings?.static_media.relationships.default_user_avatar ?? '',
  });
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const baseLanguageGoals = baseLanguage.goals;
  const locale = getLanguageFromCMS(baseLanguage.language_code);

  // Parse MetaData
  const metaDataParseResult = parseNotificationMetaData(
    notification.eventType,
    notification.metaData,
  );
  const metaData = metaDataParseResult.success
    ? metaDataParseResult.data
    : undefined;
  useEffect(() => {
    if (metaDataParseResult.success) return;
    const { error } = metaDataParseResult;
    console.error('Failed to parse notificaiton metadata', {
      notification,
      error,
    });
  }, [metaDataParseResult, notification]);

  // Build Avatar
  function getAvatarParams(): UsePictureParams[] {
    if (!metaData?.avatar) return [];
    return [
      {
        pictureId: notification.id,
        sasTokenApiPath: '/admin/storage',
        containerName: 'pictures',
        pictureUrl: undefined,
        pictureBlobName: metaData.avatar,
      },
    ];
  }
  const avatarParams = getAvatarParams();
  const { picture: avatars } = usePictureMulti({
    pictureParamsList: avatarParams,
  });

  // Common NotificationCard Props
  const programSlug = notification.programSlug;
  const program = programSlug
    ? programs.find((program) => program.metadata.id.current === programSlug)
    : undefined;
  const programName = program?.metadata.program_name;
  const programTypeCommon =
    program?.program_details?.program_type?.program_type_text?.common;
  const programTypeVerb = programTypeCommon?.verb ?? 'Mentoring';
  const programTypeNoun = programTypeCommon?.noun ?? 'Mentor';
  const unread = notification.status === 'unread';
  const date = notification.createdAt
    ? format(new Date(notification.createdAt), 'dd/MM/yy')
    : undefined;
  const avatarSrc = avatars[notification.id] ?? defaultAvatar;
  const commonProps = {
    cardRef,
    unread,
    date,
    caption: programName,
  };

  // MUI
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      threshold: 1,
    };
    const handleObserver: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];

      if (entry.isIntersecting && onVisible) {
        onVisible();
      }
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Render
  switch (metaData?.eventType) {
    case undefined: {
      return <></>;
    }
    // TODO: Uncomment once 'relationships-individual-unread-message' is implemented
    // case 'relationships-individual-unread-message': {
    //   const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
    //     metaData;

    //   return (
    //     <NotificationCardView
    //       date={date}
    //       avatarSrc={avatarSrc}
    //       heading={interpolate(
    //         baseLanguage.notifications.individual_relationships
    //           .relationships_individual_unread_message.description,
    //         {
    //           counterpartyFirstName,
    //           counterpartyLastName,
    //           programTypeVerb,
    //           programTypeNoun,
    //         },
    //       )}
    //       caption={programName}
    //       linkLabel={
    //         baseLanguage.notifications.individual_relationships
    //           .relationships_individual_unread_message.button_label
    //       }
    //       linkHref={relationshipUrl}
    //     ></NotificationCardView>
    //   );
    // }
    case 'relationships-individual-schedule-session': {
      const { counterpartyFirstName, counterpartyLastName, createSessionUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_schedule_session.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
          linkHref={createSessionUrl}
          linkLabel={
            baseLanguage.notifications.individual_relationships
              .relationships_individual_schedule_session.button_label
          }
        />
      );
    }
    case 'relationships-individual-upcoming-session': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_upcoming_session.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color="success"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={
              baseLanguage.globals?.sessions?.view_session ?? 'View Session'
            }
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
            })}
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-session-updated': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        sessionName,
        sessionStart,
        sessionEnd,
        oldSessionStart,
        oldSessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_session_updated.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color="warning"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={
              baseLanguage.globals?.sessions?.view_session ?? 'View Session'
            }
            body={getSessionDateAndDuration({
              sessionStart,
              sessionEnd,
              locale,
            })}
            strikethroughBody={getSessionDateAndDuration({
              sessionStart: oldSessionStart,
              sessionEnd: oldSessionEnd,
              locale,
            })}
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-session-deleted': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        oldSessionName,
        oldSessionStart,
        oldSessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_session_deleted.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color="error"
            label={
              <NotificationBubbleDateLabel show={isMd} date={oldSessionStart} />
            }
            heading={oldSessionName}
            strikethroughBody={getSessionDateAndDuration({
              sessionStart: oldSessionStart,
              sessionEnd: oldSessionEnd,
              locale,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-relationship-created': {
      const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_relationship_created.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
          linkLabel={
            baseLanguage.globals?.relationships?.view_relationships ??
            'View Relationship'
          }
          linkHref={relationshipUrl}
        />
      );
    }
    case 'relationships-individual-relationship-created-by-admin': {
      const { counterpartyFirstName, counterpartyLastName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_relationship_created_by_admin
              .description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
          linkLabel={
            baseLanguage.notifications.individual_relationships
              .relationships_individual_relationship_created_by_admin
              .button_label
          }
          linkHref={relationshipUrl}
        />
      );
    }
    case 'relationships-individual-relationship-concluded': {
      const { counterpartyFirstName, counterpartyLastName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_relationship_concluded.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        />
      );
    }
    case 'relationships-individual-relationship-concluded-by-admin': {
      const { counterpartyFirstName, counterpartyLastName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_relationship_concluded_by_admin
              .description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        />
      );
    }
    case 'relationships-individual-relationship-shared-goal-updated': {
      const {
        counterpartyFirstName,
        counterpartyLastName,
        goalStatus,
        goalObjective,
        manageGoalsUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_shared_goal_updated.description,
            {
              counterpartyFirstName,
              counterpartyLastName,
              programTypeVerb,
              programTypeNoun,
            },
          )}
        >
          <NotificationBubbleView
            color={getGoalStatusColor({ status: goalStatus })}
            label={getGoalStatusText({ status: goalStatus, baseLanguageGoals })}
            body={goalObjective}
            linkHref={manageGoalsUrl}
            linkLabel={baseLanguage.globals?.goals?.view_goal_button_label}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-individual-relationship-share-a-goal': {
      const { counterpartyFirstName, counterpartyLastName, manageGoalsUrl } =
        metaData;
      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.individual_relationships
              .relationships_individual_share_a_goal.description,
            { counterpartyFirstName, counterpartyLastName },
          )}
          linkHref={manageGoalsUrl}
          linkLabel={
            baseLanguage.notifications.individual_relationships
              .relationships_individual_share_a_goal.button_label
          }
        />
      );
    }
    // TODO: Uncomment once 'relationships-group-unread-message' is implemented
    // case 'relationships-group-unread-message': {
    //   const { groupName, relationshipUrl } = metaData;

    //   return (
    //     <NotificationCardView
    //       date={date}
    //       avatarSrc={avatarSrc}
    //       heading={interpolate(
    //         baseLanguage.notifications.group_relationships
    //           .relationships_group_unread_message.description,
    //         {
    //           groupName,
    //         },
    //       )}
    //       caption={programName}
    //       linkLabel={
    //         baseLanguage.notifications.group_relationships
    //           .relationships_group_unread_message.button_label
    //       }
    //       linkHref={relationshipUrl}
    //     ></NotificationCardView>
    //   );
    // }
    case 'relationships-group-schedule-session': {
      const { guideFirstName, guideLastName, groupName, createSessionUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.group_relationships
              .relationships_group_schedule_session.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
          linkLabel={baseLanguage.globals.sessions.schedule_session}
          linkHref={createSessionUrl}
        />
      );
    }
    case 'relationships-group-session-deleted': {
      const {
        guideFirstName,
        guideLastName,
        groupName,
        oldSessionName,
        oldSessionStart,
        oldSessionEnd,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.group_relationships
              .relationships_group_session_deleted.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        >
          <NotificationBubbleView
            color="error"
            label={
              <NotificationBubbleDateLabel show={isMd} date={oldSessionStart} />
            }
            heading={oldSessionName}
            strikethroughBody={getSessionDateAndDuration({
              sessionStart: oldSessionStart,
              sessionEnd: oldSessionEnd,
              locale,
            })}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-group-session-updated': {
      const {
        guideFirstName,
        guideLastName,
        groupName,
        sessionName,
        sessionStart,
        sessionEnd,
        oldSessionStart,
        oldSessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.group_relationships
              .relationships_group_session_updated.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        >
          <NotificationBubbleView
            color="warning"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={baseLanguage.globals.sessions.view_session}
            body={getSessionDateAndDuration({
              sessionStart,
              sessionEnd,
              locale,
            })}
            strikethroughBody={getSessionDateAndDuration({
              sessionStart: oldSessionStart,
              sessionEnd: oldSessionEnd,
              locale,
            })}
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-group-upcoming-session': {
      const {
        guideFirstName,
        guideLastName,
        groupName,
        sessionName,
        sessionStart,
        sessionEnd,
        sessionUrl,
      } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.group_relationships
              .relationships_group_upcoming_session.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        >
          <NotificationBubbleView
            color="success"
            label={
              <NotificationBubbleDateLabel show={isMd} date={sessionStart} />
            }
            heading={sessionName}
            linkLabel={baseLanguage.globals.sessions.view_session}
            body={getSessionDateAndDuration({
              sessionEnd,
              sessionStart,
              locale,
            })}
            linkHref={sessionUrl}
          />
        </NotificationCardView>
      );
    }
    case 'relationships-group-relationship-guide-concluded': {
      const { guideFirstName, guideLastName, groupName } = metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.group_relationships
              .relationships_group_relationship_guide_concluded.description,
            {
              guideFirstName,
              guideLastName,
              groupName,
            },
          )}
        />
      );
    }
    case 'relationships-group-relationship-trainee-left': {
      const { traineeFirstName, traineeLastName, groupName, relationshipUrl } =
        metaData;

      return (
        <NotificationCardView
          {...commonProps}
          avatarSrc={avatarSrc}
          heading={interpolate(
            baseLanguage.notifications.group_relationships
              .relationships_group_trainee_left_group.description,
            {
              traineeFirstName,
              traineeLastName,
              groupName,
            },
          )}
          linkLabel={
            baseLanguage.notifications.group_relationships
              .relationships_group_trainee_left_group.button_label
          }
          linkHref={relationshipUrl}
        />
      );
    }
    default: {
      return <></>;
    }
  }
}
