import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { ISkill, Program } from '@guider-global/shared-types';
import { format, isAfter } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import {
  ProgramMembershipsCard,
  ProgramMembershipsGuideContent,
  ProgramMembershipsTraineeContent,
} from 'views/dashboard/ProgramMembershipsCard';
import { IDashbordRelationshipsRow } from '../../../views/dashboard/ProgramMembershipTableView';
import { ProgramMembershipEmptyTableViewProps } from '../../../views/dashboard/ProgramMembershipEmptyTableView';
import { ProgramMembershipDetailsViewProps } from '../../../views/dashboard/ProgramMembershipDetailsView';
import { useMemo } from 'react';
import { useMemberships, useProfiles, useRelationships } from 'hooks';
import {
  getFormattedProgramFields,
  getFormattedSkills,
} from '@guider-global/front-end-utils';
import { interpolate } from 'functions';
import { useTheme } from '@mui/material';

export type GroupProgramMembershipsContainerProps = {
  program: Program;
};

export function GroupProgramMembershipsContainer({
  program,
}: GroupProgramMembershipsContainerProps) {
  const navigate = useNavigate();
  const { palette } = useTheme();

  //Settings
  const { settings } = useSanitySettings({});
  const relationshipsEmptyStateAvatar = buildSanityImageUrl({
    source:
      settings?.static_media.relationships.no_relationships?.asset._ref ?? '',
    width: 180,
  });
  // Sanity Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const relationshipsBaseLanguage =
    baseLanguage.dashboard.program_memberships.with_program_memberships
      .relationships_table;
  const membershipDetailsBaseLanguage =
    baseLanguage.dashboard.program_memberships.with_program_memberships
      .membership_details;
  const groupMembershipBaseLanguage =
    baseLanguage.dashboard.program_memberships.with_program_memberships
      .membership_details.group_membership;

  const programTypeText =
    program.program_details?.program_type?.program_type_text.common;
  const editProfileButtonLabel =
    baseLanguage.globals?.common?.edit_profile_button_label ?? 'Edit Profile';

  // Membership Summaries
  const { memberships: getMemberships } = useMemberships({});
  const memberships = getMemberships();

  // Profiles
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();

  // Relationship profiles

  const { relationships: getRelationships } = useRelationships({});
  const relationshipProfilesData = getRelationships();

  //----------PROGRAM DATA----------

  const programData = useMemo(() => {
    const guideMembership = memberships
      .filter(
        (membership) =>
          membership.programSlug === program.metadata.id.current &&
          membership.role === 'guide',
      )
      .at(0);

    const traineeMembership = memberships
      .filter(
        (membership) =>
          membership.programSlug === program.metadata.id.current &&
          membership.role === 'trainee',
      )
      .at(0);

    const programRelationshipProfiles = relationshipProfilesData.filter(
      (relationship) =>
        relationship.programSlug === program.metadata.id.current &&
        !relationship.isConcluded,
    );

    const guideRelationshipProfiles = programRelationshipProfiles.filter(
      (relationship) => {
        return relationship.guideProfiles?.find(
          (guideProfile) => guideProfile.id === profile.id,
        );
      },
    );

    const traineeRelationshipProfiles = programRelationshipProfiles.filter(
      (relationship) => {
        return relationship.traineeProfiles?.find(
          (trainee) => trainee.id === profile?.id,
        );
      },
    );

    const traineeRelationshipLimitReached =
      (program.relationships.relationship_limits
        ?.enable_trainee_relationship_limit &&
        !!program.relationships?.relationship_limits
          .trainee_relationship_limit &&
        traineeRelationshipProfiles.length >=
          program.relationships?.relationship_limits
            .trainee_relationship_limit) ??
      false;

    const relationshipsTableHeaders = {
      name: relationshipsBaseLanguage.table_headers?.group_name ?? 'Name',
      numberOfSessions:
        relationshipsBaseLanguage.table_headers?.number_of_sessions ??
        'Past sessions',
      nextSession:
        relationshipsBaseLanguage.table_headers?.next_session ?? 'Next session',
    };

    const programAvatar = buildSanityImageUrl({
      source: program.program_details?.program_image.asset ?? '',
      width: 300,
    });

    const programCardTitle = program.metadata.program_name;

    return {
      programCardTitle,
      programAvatar,
      traineeMembership,
      traineeRelationshipProfiles,
      traineeRelationshipLimitReached,
      guideMembership,
      guideRelationshipProfiles,
      relationshipsTableHeaders,
    };
  }, [
    memberships,
    relationshipProfilesData,
    program.relationships.relationship_limits
      ?.enable_trainee_relationship_limit,
    program.relationships.relationship_limits?.trainee_relationship_limit,
    program.program_details?.program_image.asset,
    program.metadata.program_name,
    program.metadata.id,
    relationshipsBaseLanguage.table_headers?.group_name,
    relationshipsBaseLanguage.table_headers?.number_of_sessions,
    relationshipsBaseLanguage.table_headers?.next_session,
    profile.id,
  ]);

  const {
    programAvatar,
    programCardTitle,
    traineeMembership,
    traineeRelationshipProfiles,
    traineeRelationshipLimitReached,
    guideMembership,
    guideRelationshipProfiles,
    relationshipsTableHeaders,
  } = programData;

  //----------TRAINEE MEMBERSHIP----------

  const traineeMembershipData: ProgramMembershipsTraineeContent =
    useMemo(() => {
      //Return undefined fast if no trainee membership
      if (!traineeMembership) {
        return {
          traineeMembershipDetails: undefined,
          traineeRelationshipRows: [],
          traineeRelationshipsEmptyState: undefined,
        };
      }

      //Trainee membership fields
      const traineeSkills = getFormattedSkills(
        'trainee',
        traineeMembership.skills as Partial<ISkill>[],
        program.registration?.skills,
      );

      const traineeFields = getFormattedProgramFields(
        traineeMembership,
        program,
      );

      traineeFields.unshift(traineeSkills);

      //Trainee membership details

      const traineeDetailsTitle =
        interpolate(membershipDetailsBaseLanguage.profile, {
          roleSingular: programTypeText?.trainee?.singular ?? 'mentee',
        }) ?? 'Yout mentee profile';

      const traineeDetailsDescription =
        interpolate(membershipDetailsBaseLanguage?.creation_date, {
          date: format(
            new Date(traineeMembership.createdAt ?? ''),
            'EEE do MMM',
          ),
        }) ?? new Date(traineeMembership.createdAt ?? '');

      const hasTraineeRelationships = traineeRelationshipProfiles.length > 0;

      const activeButtonLabel =
        groupMembershipBaseLanguage.group_member?.find_group_label ??
        'Find a group';
      const disabledButtonLabel =
        groupMembershipBaseLanguage.group_member?.group_limit_reached ??
        'Group limit reached';

      const traineeDetailsButton = hasTraineeRelationships
        ? {
            label: traineeRelationshipLimitReached
              ? disabledButtonLabel
              : activeButtonLabel,
            onClick: () =>
              navigate(`/programs/${program.metadata.id.current}/join/trainee`),
            disabled: traineeRelationshipLimitReached,
          }
        : undefined;

      const traineeMembershipDetails: ProgramMembershipDetailsViewProps = {
        title: traineeDetailsTitle,
        description: traineeDetailsDescription,
        fields: traineeFields,
        button: traineeDetailsButton,
      };

      let traineeRelationshipRows: IDashbordRelationshipsRow[] = [];
      let traineeRelationshipsEmptyState:
        | ProgramMembershipEmptyTableViewProps
        | undefined = undefined;

      //Trainee relationships
      if (hasTraineeRelationships) {
        traineeRelationshipRows = traineeRelationshipProfiles.map(
          (relationship) => {
            const sessions = relationship.sessions ?? [];
            const sessionsLength = sessions?.length ?? 0;

            const pastSessions =
              sessionsLength > 0
                ? sessions
                    .slice()
                    .filter(
                      (session) =>
                        !isAfter(new Date(session?.start ?? ''), new Date()),
                    )
                : [];

            const upcomingSessions =
              sessionsLength > 0
                ? sessions
                    .slice()
                    .filter(
                      (session) =>
                        !session.isArchived &&
                        isAfter(new Date(session?.start ?? ''), new Date()),
                    )
                    .sort((a, b) => {
                      return a.start && b.start && a.start < b.start ? -1 : 1;
                    })
                : [];

            const nextSessionLabel =
              upcomingSessions?.length === 0
                ? relationshipsBaseLanguage?.no_session_placeholder
                : format(
                    new Date(upcomingSessions?.at(0)?.start ?? ''),
                    'EEE, do MMM',
                  ) +
                  ' ' +
                  format(
                    new Date(upcomingSessions?.at(0)?.start ?? ''),
                    'h:mmaaa',
                  );

            const description = relationship?.createdAt
              ? format(new Date(relationship.createdAt), 'd/M/yyyy')
              : '';

            const guideProfile = relationship.guideProfiles?.at(0);
            const guideUser = relationship.users?.find(
              (user) => user.id === guideProfile?.userId,
            );
            return {
              onClick: () => {
                navigate(`/relationships/${relationship.id}`);
              },
              userPicture: guideUser?.picture,
              profilePicture: guideProfile?.picture,
              name: relationship.title ?? '',
              description,
              numberOfSessions: pastSessions.length.toString() ?? 0,
              nextSessionLabel: nextSessionLabel,
              nextSessionColor:
                upcomingSessions.length > 0
                  ? palette.success.main
                  : palette.error.main,
            };
          },
        );
      } else {
        //Trainee relationships empty state

        const emptyStateHeading =
          interpolate(
            relationshipsBaseLanguage.trainee_empty_state.group?.title,
            {
              roleSingular: programTypeText?.trainee?.singular ?? 'Mentee',
              rolePlural: programTypeText?.trainee?.pluralized ?? 'Mentees',
              programVerb: programTypeText?.verb ?? 'Mentoring',
            },
          ) ?? `You don't have any relationships yet`;

        const emptyStateDescriptions = [
          interpolate(
            relationshipsBaseLanguage.trainee_empty_state.group?.description,
            {
              roleSingular: programTypeText?.guide?.singular ?? 'Mentor',
              rolePlural: programTypeText?.guide?.singular ?? 'Mentors',
              programVerb: programTypeText?.verb ?? 'Mentoring',
            },
          ) ?? '',
          relationshipsBaseLanguage.trainee_empty_state.group?.description_2 ??
            '',
        ];

        const emptyStateLeftButton = {
          label: editProfileButtonLabel,
          onClick: () =>
            navigate(`/programs/${program.metadata.id.current}/join/trainee`),
          disabled: traineeRelationshipLimitReached,
        };

        const emptyStateRightButton = {
          label:
            relationshipsBaseLanguage.trainee_empty_state.group
              ?.select_group_button ?? 'Select a group',
          onClick: () =>
            navigate(`/programs/${program.metadata.id.current}/join/trainee`),
          disabled: traineeRelationshipLimitReached,
        };

        traineeRelationshipsEmptyState = {
          heading: emptyStateHeading,
          descriptions: emptyStateDescriptions,
          leftButton: emptyStateLeftButton,
          rightButton: emptyStateRightButton,
        };
      }

      return {
        traineeMembershipDetails,
        traineeRelationshipRows,
        traineeRelationshipsEmptyState,
      };
    }, [
      traineeMembership,
      program,
      membershipDetailsBaseLanguage.profile,
      membershipDetailsBaseLanguage?.creation_date,
      programTypeText?.trainee?.singular,
      programTypeText?.trainee?.pluralized,
      programTypeText?.verb,
      programTypeText?.guide?.singular,
      traineeRelationshipProfiles,
      groupMembershipBaseLanguage.group_member?.find_group_label,
      groupMembershipBaseLanguage.group_member?.group_limit_reached,
      traineeRelationshipLimitReached,
      navigate,
      relationshipsBaseLanguage?.no_session_placeholder,
      relationshipsBaseLanguage.trainee_empty_state.group?.title,
      relationshipsBaseLanguage.trainee_empty_state.group?.description,
      relationshipsBaseLanguage.trainee_empty_state.group?.description_2,
      relationshipsBaseLanguage.trainee_empty_state.group?.select_group_button,
      palette.success.main,
      palette.error.main,
      editProfileButtonLabel,
    ]);

  //----------GUIDE MEMBERSHIP----------

  const guideMembershipData: ProgramMembershipsGuideContent = useMemo(() => {
    //Return undefined fast if no guide membership
    if (!guideMembership) {
      return {
        guideMembershipDetails: undefined,
        guideRelationshipRows: [],
        guideRelationshipsEmptyState: undefined,
      };
    }

    //Guide membership fields

    const guideSkills = getFormattedSkills(
      'guide',
      guideMembership.skills as Partial<ISkill>[],
      program.registration?.skills,
    );

    const guideFields = getFormattedProgramFields(guideMembership, program);

    guideFields.unshift(guideSkills);

    //Guide membership details

    const guideDetailsTitle =
      interpolate(membershipDetailsBaseLanguage.profile, {
        roleSingular: programTypeText?.guide?.singular ?? 'mentor',
      }) ?? 'Your mentor profile';

    const guideDetailsDescription =
      interpolate(membershipDetailsBaseLanguage?.creation_date, {
        date: format(new Date(guideMembership.createdAt ?? ''), 'EEE do MMM'),
      }) ?? new Date(guideMembership.createdAt ?? '');

    const hasGuideRelationships = guideRelationshipProfiles.length > 0;

    const guideDetailsButton = hasGuideRelationships
      ? {
          label:
            program.program_details?.program_type?.program_type_text?.variations
              ?.group?.program_actions?.guide
              ?.manage_guide_profile_button_label ?? 'Manage your group',
          onClick: () =>
            navigate(`/programs/${program.metadata.id.current}/manage/guide`),
          disabled: false,
        }
      : undefined;

    const guideMembershipDetails: ProgramMembershipDetailsViewProps = {
      title: guideDetailsTitle,
      description: guideDetailsDescription,
      fields: guideFields,
      button: guideDetailsButton,
    };

    let guideRelationshipRows: IDashbordRelationshipsRow[] = [];
    let guideRelationshipsEmptyState:
      | ProgramMembershipEmptyTableViewProps
      | undefined = undefined;

    //Guide relationships
    if (hasGuideRelationships) {
      guideRelationshipRows = guideRelationshipProfiles.map((relationship) => {
        const groupName = relationship.title ?? '';
        const sessions = relationship.sessions ?? [];

        const pastSessions =
          sessions.length > 0
            ? sessions
                .slice()
                .filter(
                  (session) =>
                    !isAfter(new Date(session?.start ?? ''), new Date()),
                )
            : [];

        const upcomingSessions =
          sessions.length > 0
            ? sessions
                .slice()
                .filter(
                  (session) =>
                    !session.isArchived &&
                    isAfter(new Date(session?.start ?? ''), new Date()),
                )
                .sort((a, b) => {
                  return a.start && b.start && a.start < b.start ? -1 : 1;
                })
            : [];

        const nextSessionLabel =
          upcomingSessions.length === 0
            ? relationshipsBaseLanguage?.no_session_placeholder
            : format(
                new Date(upcomingSessions?.at(0)?.start ?? ''),
                'EEE, do MMM',
              ) +
              ' ' +
              format(new Date(upcomingSessions?.at(0)?.start ?? ''), 'h:mmaaa');

        const description = relationship?.createdAt
          ? interpolate(membershipDetailsBaseLanguage?.creation_date, {
              date: format(new Date(relationship.createdAt), 'd/M/yyyy'),
            }) ?? new Date(relationship.createdAt)
          : '';

        const guideProfile = relationship.guideProfiles?.at(0);
        const guideUser = relationship.users?.find(
          (user) => user.id === guideProfile?.userId,
        );

        return {
          onClick: () => {
            navigate(`/relationships/${relationship.id}`);
          },
          userPicture: guideProfile?.picture,
          profilePicture: guideUser?.picture,
          name: groupName,
          description: description,
          numberOfSessions: pastSessions.length.toString(),
          nextSessionLabel: guideMembership.isPublished
            ? program?.program_details?.program_type?.program_type_text
                .variations?.group?.relationships?.guide
                ?.group_activation_pending?.start_group_button_label ??
              'Activate group'
            : nextSessionLabel,
          nextSessionColor:
            guideMembership.isPublished || upcomingSessions.length > 0
              ? palette.success.main
              : palette.error.main,
        };
      });
    } else {
      //Guide relationships empty state

      const emptyStateHeading =
        interpolate(relationshipsBaseLanguage.guide_empty_state.group?.title, {
          roleSingular: programTypeText?.trainee?.singular ?? 'Mentee',
          rolePlural: programTypeText?.trainee?.pluralized ?? 'Mentees',
          programVerb: programTypeText?.verb ?? 'Mentoring',
        }) ?? `You don't have any relationships yet`;

      const emptyStateDescriptions = [
        interpolate(
          relationshipsBaseLanguage.guide_empty_state.group?.description,
          {
            roleSingular: programTypeText?.trainee?.singular ?? 'Mentee',
            rolePlural: programTypeText?.trainee?.pluralized ?? 'Mentees',
            programVerb: programTypeText?.verb ?? 'Mentoring',
          },
        ) ?? '',
        relationshipsBaseLanguage.guide_empty_state.group?.description_2 ?? '',
      ];

      const emptyStateLeftButton = {
        label:
          relationshipsBaseLanguage.guide_empty_state.individual
            ?.learning_hub_button_label ?? 'View Learning Hub',
        onClick: () => navigate('/learn'),
      };

      const emptyStateRightButton = {
        label:
          relationshipsBaseLanguage.guide_empty_state.group
            ?.create_group_button ?? 'Creat a group',
        onClick: () =>
          navigate(`/programs/${program.metadata.id.current}/manage/guide`),
      };

      guideRelationshipsEmptyState = {
        heading: emptyStateHeading,
        descriptions: emptyStateDescriptions,
        leftButton: emptyStateLeftButton,
        rightButton: emptyStateRightButton,
      };
    }

    return {
      guideMembershipDetails,
      guideRelationshipRows,
      guideRelationshipsEmptyState,
    };
  }, [
    guideMembership,
    program,
    membershipDetailsBaseLanguage.profile,
    membershipDetailsBaseLanguage?.creation_date,
    programTypeText?.guide?.singular,
    programTypeText?.trainee?.singular,
    programTypeText?.trainee?.pluralized,
    programTypeText?.verb,
    guideRelationshipProfiles,
    navigate,
    relationshipsBaseLanguage?.no_session_placeholder,
    relationshipsBaseLanguage.guide_empty_state.group?.title,
    relationshipsBaseLanguage.guide_empty_state.group?.description,
    relationshipsBaseLanguage.guide_empty_state.group?.description_2,
    relationshipsBaseLanguage.guide_empty_state.group?.create_group_button,
    relationshipsBaseLanguage.guide_empty_state.individual
      ?.learning_hub_button_label,
    palette.success.main,
    palette.error.main,
  ]);

  return (
    <ProgramMembershipsCard
      title={programCardTitle}
      avatarSrc={programAvatar}
      relationshipsEmptyStateAvatar={relationshipsEmptyStateAvatar}
      guideMembership={guideMembershipData}
      traineeMembership={traineeMembershipData}
      relationshipsTableHeaders={relationshipsTableHeaders}
    />
  );
}
