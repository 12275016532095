import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { DashboardOnboardingView } from 'views/dashboard';
import {
  PrimaryPanelUserAvatar,
  PrimaryPanelOtherPartyAvatar,
} from 'components/Dashboard';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';

export function IndividualProgramScheduleSession() {
  const navigate = useNavigate();
  const { programName, relationshipId, isGuide } = useContext(
    DashboardNotificationsContext,
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const {
    title,
    description,
    schedule_a_session_button_label: primaryButtonLabel,
    connect_calendar_button_label: secondaryButtonLabel,
    connect_calendar_button_description: secondaryButtonCaption,
  } = baseLanguage.dashboard.onboarding.steps.individual_program
    .schedule_a_session;

  const organizationSlug = getSubDomain();
  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const { white_label: whiteLabel } = getOrganization();
  const isCalendarIntegrationEnabled =
    whiteLabel.integrations.calendar.active_directory_outlook_calendar;

  const onPrimaryButtonClick = () => {
    navigate(`/relationships/${relationshipId}/sessions/create`);
  };

  const onSecondaryButtonClick = () => {
    navigate('/settings/integrations');
  };

  return (
    <DashboardOnboardingView
      avatarA={<PrimaryPanelUserAvatar />}
      avatarB={<PrimaryPanelOtherPartyAvatar isGuide={isGuide} />}
      programLabel={programName}
      heading={title}
      description={description}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonLabel={
        isCalendarIntegrationEnabled ? secondaryButtonLabel : undefined
      }
      secondaryButtonCaption={
        isCalendarIntegrationEnabled ? secondaryButtonCaption : undefined
      }
      onSecondaryButtonClick={
        isCalendarIntegrationEnabled ? onSecondaryButtonClick : undefined
      }
    />
  );
}
