import { INotification } from '@guider-global/shared-types';
import {
  KeyedNotificationMetaData,
  notificationMetaDataSchema,
} from './schema';
import { SafeParseReturnType } from 'zod';

export type NotificationMetaDataRecord = {
  [k: string]: string | number | boolean | Date;
};

export function parseNotificationMetaData(
  eventType: INotification['eventType'],
  notificationMetaData: INotification['metaData'],
): SafeParseReturnType<NotificationMetaDataRecord, KeyedNotificationMetaData> {
  const metaDataEntries = notificationMetaData.flatMap((metaDataObject) =>
    Object.entries(metaDataObject),
  );
  const metaDataRecord = Object.fromEntries(metaDataEntries);
  const metaDataParseResult = notificationMetaDataSchema.safeParse({
    eventType,
    ...metaDataRecord,
  });
  return metaDataParseResult;
}
