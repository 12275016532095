import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useModalNotifications } from 'hooks/useNotificationsModal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllCaughtUpView, NotificationHighlightsView } from 'views';
import { NotificationModalContainer } from '../NotificationModalContainer';
import { NotificationCardContainer } from '../notificationCardContainer';
import { Loading } from '@guider-global/ui';

export function NotificationHighlightsContainer() {
  const navigate = useNavigate();
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  // Sanity
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const buttonLabel =
    baseLanguage.dashboard.notifications.panel
      .view_all_notifications_button_label;

  useModalNotifications({
    getSilently: true,
    isPaginated: true,
    pageLimit: 10,
  });

  const {
    getNotifications,
    reqLatestNotifications,
    getIsLoadingNotifications,
    getResponse,
  } = useNotifications({
    getSilently: false,
  });
  const notifications = getNotifications();
  const notificationsResponse = getResponse();
  const isLoadingNotifications = getIsLoadingNotifications();
  useEffect(() => {
    if (notificationsResponse) return;
    if (isLoadingNotifications) return;
    reqLatestNotifications();
  }, [isLoadingNotifications, notificationsResponse, reqLatestNotifications]);

  const openNotificationModal = () => {
    setShowNotificationModal(true);
  };

  const closeNotificationModal = () => {
    setShowNotificationModal(false);
  };

  if (isLoadingNotifications) {
    return <Loading sx={{ height: '100%', color: 'white' }} />;
  }

  return (
    <>
      {notifications.length === 0 ? (
        <AllCaughtUpView
          title={baseLanguage.dashboard.notifications.empty_state.title}
          description={
            baseLanguage.dashboard.notifications.empty_state.description
          }
          primaryButtonLabel={
            baseLanguage.dashboard.notifications.empty_state
              .explore_learning_hub
          }
          onPrimaryButtonClick={() => navigate('/learn')}
          secondaryButtonLabel={
            baseLanguage.globals.relationships.view_relationships
          }
          onSecondaryButtonClick={() => navigate('/relationships')}
          tertiaryButtonLabel={buttonLabel}
          onTertiaryButtonClick={openNotificationModal}
        />
      ) : (
        <NotificationHighlightsView
          buttonLabel={buttonLabel}
          onButtonClick={openNotificationModal}
        >
          {notifications.map((notification) => (
            <NotificationCardContainer
              key={notification.id}
              notification={notification}
            />
          ))}
        </NotificationHighlightsView>
      )}
      <NotificationModalContainer
        open={showNotificationModal}
        onClose={closeNotificationModal}
      />
    </>
  );
}
