import { useContext } from 'react';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { ProgramCtaContainer } from '../ProgramCtaContainer';
import { IndividualProgramTraineeMembershipPublished } from '../IndividualProgramTraineeMembershipPublished';
import { IndividualProgramScheduleSession } from '../IndividualProgramScheduleSession';
import { NotificationHighlightsContainer } from '../NotificationHighlightsContainer';

export function IndividualProgramTrainee() {
  const {
    hasMemberships,
    hasRelationships,
    hasSessions,
    hasAttendedSessions,
    hasCompletedOnboarding,
  } = useContext(DashboardNotificationsContext);

  if (hasCompletedOnboarding) {
    return <NotificationHighlightsContainer />;
  }

  if (!hasMemberships) {
    return <ProgramCtaContainer />;
  }

  if (!hasRelationships) {
    return <IndividualProgramTraineeMembershipPublished />;
  }

  if (!hasSessions) {
    return <IndividualProgramScheduleSession />;
  }

  if (!hasAttendedSessions) {
    return <NotificationHighlightsContainer />;
  }
}
