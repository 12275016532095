import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanitySettings,
} from '@guider-global/sanity-hooks';
import { ISkill, Program } from '@guider-global/shared-types';
import { ChipProps } from '@guider-global/ui';
import { format, isAfter } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  ProgramMembershipsCard,
  ProgramMembershipsGuideContent,
  ProgramMembershipsTraineeContent,
} from 'views/dashboard/ProgramMembershipsCard';
import { IDashbordRelationshipsRow } from '../../../views/dashboard/ProgramMembershipTableView';
import { ProgramMembershipEmptyTableViewProps } from '../../../views/dashboard/ProgramMembershipEmptyTableView';
import { ProgramMembershipDetailsViewProps } from '../../../views/dashboard/ProgramMembershipDetailsView';
import { useMemberships, useProfiles, useRelationships } from 'hooks';
import { useMemo } from 'react';
import {
  getFormattedProgramFields,
  getFormattedSkills,
} from '@guider-global/front-end-utils';
import { interpolate } from 'functions';
import { useTheme } from '@mui/material';

export type IndividualProgramMembershipsContainerProps = {
  program: Program;
};

export function IndividualProgramMembershipsContainer({
  program,
}: IndividualProgramMembershipsContainerProps) {
  const navigate = useNavigate();

  const { palette } = useTheme();

  //Settings
  const { settings } = useSanitySettings({});
  const relationshipsEmptyStateAvatar = buildSanityImageUrl({
    source:
      settings?.static_media.relationships.no_relationships?.asset._ref ?? '',
    width: 180,
  });
  // Sanity Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const relationshipsBaseLanguage =
    baseLanguage.dashboard.program_memberships.with_program_memberships
      .relationships_table;
  const membershipDetailsBaseLanguage =
    baseLanguage.dashboard.program_memberships.with_program_memberships
      .membership_details;

  const programTypeText =
    program.program_details?.program_type?.program_type_text.common;

  const editProfileButtonLabel =
    baseLanguage.globals?.common?.edit_profile_button_label ?? 'Edit Profile';

  // Membership Summaries
  const { memberships: getMemberships } = useMemberships({});
  const memberships = getMemberships();

  // Profiles
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();

  // Relationship profiles

  const { relationships: getRelationships } = useRelationships({});
  const relationshipsData = getRelationships();

  //----------PROGRAM DATA----------

  const programData = useMemo(() => {
    const guideMembership = memberships
      .filter(
        (membership) =>
          membership.programSlug === program.metadata.id.current &&
          membership.role === 'guide',
      )
      .at(0);

    const traineeMembership = memberships
      .filter(
        (membership) =>
          membership.programSlug === program.metadata.id.current &&
          membership.role === 'trainee',
      )
      .at(0);

    const programRelationships = relationshipsData.filter(
      (relationship) =>
        relationship.programSlug === program.metadata.id.current &&
        !relationship.isConcluded,
    );

    const guideRelationships = programRelationships.filter((relationship) => {
      return relationship.guideProfiles?.find(
        (guideProfile) => guideProfile.id === profile.id,
      );
    });

    const traineeRelationships = programRelationships.filter((relationship) => {
      return relationship.traineeProfiles?.find(
        (trainee) => trainee.id === profile?.id,
      );
    });

    const traineeRelationshipLimitReached =
      (program.relationships.relationship_limits
        ?.enable_trainee_relationship_limit &&
        !!program.relationships?.relationship_limits
          .trainee_relationship_limit &&
        traineeRelationships.length >=
          program.relationships?.relationship_limits
            .trainee_relationship_limit) ??
      false;

    const relationshipsTableHeaders = {
      name: relationshipsBaseLanguage.table_headers?.user_name ?? 'Name',
      numberOfSessions:
        relationshipsBaseLanguage.table_headers?.number_of_sessions ??
        'Past sessions',
      nextSession:
        relationshipsBaseLanguage.table_headers?.next_session ?? 'Next session',
    };

    const programAvatar = buildSanityImageUrl({
      source: program.program_details?.program_image.asset ?? '',
      width: 300,
    });

    const programCardTitle = program.metadata.program_name;

    const guideCount = traineeRelationships.length;

    const traineeCount = guideRelationships.length;

    const guideCountLabel =
      guideCount === 1
        ? `${guideCount} ${programTypeText?.guide?.singular}`
        : `${guideCount} ${programTypeText?.guide?.pluralized}`;

    const traineeCountLabel =
      traineeCount === 1
        ? `${traineeCount} ${programTypeText?.trainee?.singular}`
        : `${traineeCount} ${programTypeText?.trainee?.pluralized}`;

    const programCardDescription = `${guideCountLabel} • ${traineeCountLabel}`;

    return {
      programAvatar,
      programCardTitle,
      programCardDescription,
      traineeMembership,
      traineeRelationships,
      traineeRelationshipLimitReached,
      guideMembership,
      guideRelationships,
      relationshipsTableHeaders,
    };
  }, [
    memberships,
    relationshipsData,
    program.relationships.relationship_limits
      ?.enable_trainee_relationship_limit,
    program.relationships.relationship_limits?.trainee_relationship_limit,
    program.program_details?.program_image.asset,
    program.metadata.program_name,
    program.metadata.id,
    relationshipsBaseLanguage.table_headers?.user_name,
    relationshipsBaseLanguage.table_headers?.number_of_sessions,
    relationshipsBaseLanguage.table_headers?.next_session,
    programTypeText?.guide?.singular,
    programTypeText?.guide?.pluralized,
    programTypeText?.trainee?.singular,
    programTypeText?.trainee?.pluralized,
    profile.id,
  ]);

  const {
    programAvatar,
    programCardTitle,
    programCardDescription,
    traineeMembership,
    traineeRelationships,
    traineeRelationshipLimitReached,
    guideMembership,
    guideRelationships,
    relationshipsTableHeaders,
  } = programData;

  //----------TRAINEE MEMBERSHIP----------

  const traineeMembershipData: ProgramMembershipsTraineeContent =
    useMemo(() => {
      //Return undefined fast if no trainee membership
      if (!traineeMembership) {
        return {
          traineeMembershipDetails: undefined,
          traineeRelationshipRows: [],
          traineeMembershipEmptyState: undefined,
        };
      }

      //Trainee membership fields
      const traineeSkills = getFormattedSkills(
        'trainee',
        traineeMembership.skills as Partial<ISkill>[],
        program.registration?.skills,
      );

      const traineeFields = getFormattedProgramFields(
        traineeMembership,
        program,
      );

      traineeFields.unshift(traineeSkills);

      //Trainee membership details

      const traineeDetailsTitle =
        interpolate(membershipDetailsBaseLanguage.profile, {
          roleSingular: programTypeText?.trainee?.singular ?? 'mentee',
        }) ?? 'Your mentee profile';

      const traineeDetailsDescription =
        interpolate(membershipDetailsBaseLanguage?.creation_date, {
          date: format(
            new Date(traineeMembership.createdAt ?? ''),
            'EEE do MMM',
          ),
        }) ?? new Date(traineeMembership.createdAt ?? '');

      const hasTraineeRelationships = traineeRelationships.length > 0;

      const disabledButtonLabel =
        interpolate(
          membershipDetailsBaseLanguage.individual_membership?.trainee
            ?.guide_limit_reached,
          {
            roleSingular: programTypeText?.guide?.singular ?? 'Mentor',
          },
        ) ?? 'Mentor limit reached';
      const activeButtonLabel =
        interpolate(
          membershipDetailsBaseLanguage.individual_membership?.trainee
            ?.find_another_guide,
          {
            roleSingular: programTypeText?.guide?.singular ?? 'Mentor',
          },
        ) ?? 'Find another Mentor';

      const traineeDetailsButton = hasTraineeRelationships
        ? {
            label: traineeRelationshipLimitReached
              ? disabledButtonLabel
              : activeButtonLabel,
            onClick: () =>
              navigate(`/programs/${program.metadata.id.current}/join/trainee`),
            disabled: traineeRelationshipLimitReached,
          }
        : undefined;

      const traineeMembershipDetails: ProgramMembershipDetailsViewProps = {
        title: traineeDetailsTitle,
        description: traineeDetailsDescription,
        fields: traineeFields,
        button: traineeDetailsButton,
      };

      let traineeRelationshipRows: IDashbordRelationshipsRow[] = [];
      let traineeRelationshipsEmptyState:
        | ProgramMembershipEmptyTableViewProps
        | undefined = undefined;

      //Trainee relationships

      if (hasTraineeRelationships) {
        traineeRelationshipRows = traineeRelationships.map((relationship) => {
          const guideProfile = relationship.guideProfiles?.at(0);
          const guideName =
            guideProfile?.displayName ??
            `${guideProfile?.firstName} ${guideProfile?.lastName}`;
          const guideUser = relationship.users?.find(
            (user) => user.id === guideProfile?.userId,
          );
          const sessions = relationship.sessions ?? [];

          const pastSessions =
            sessions.length > 0
              ? sessions
                  .slice()
                  .filter(
                    (session) =>
                      !isAfter(new Date(session?.start ?? ''), new Date()),
                  )
              : [];

          const upcomingSessions =
            sessions.length > 0
              ? sessions
                  .slice()
                  .filter(
                    (session) =>
                      !session.isArchived &&
                      isAfter(new Date(session?.start ?? ''), new Date()),
                  )
                  .sort((a, b) => {
                    return a.start && b.start && a.start < b.start ? -1 : 1;
                  })
              : [];

          const nextSessionLabel =
            upcomingSessions.length === 0
              ? relationshipsBaseLanguage?.no_session_placeholder
              : format(
                  new Date(upcomingSessions?.at(0)?.start ?? ''),
                  'EEE, do MMM',
                ) +
                ' ' +
                format(
                  new Date(upcomingSessions?.at(0)?.start ?? ''),
                  'h:mmaaa',
                );

          return {
            onClick: () => {
              navigate(`/relationships/${relationship.id}`);
            },
            userPicture: guideUser?.picture,
            profilePicture: guideProfile?.picture,
            name: guideName,
            description: traineeMembership.createdAt
              ? format(new Date(traineeMembership.createdAt), 'd/M/yyyy')
              : '',
            numberOfSessions: pastSessions.length.toString(),
            nextSessionLabel: nextSessionLabel,
            nextSessionColor:
              upcomingSessions.length > 0
                ? palette.success.main
                : palette.error.main,
          };
        });
      } else {
        //Trainee relationships empty state

        const emptyStateHeading =
          interpolate(
            relationshipsBaseLanguage.trainee_empty_state.individual?.title,
            {
              roleSingular: programTypeText?.guide?.singular ?? 'Mentor',
            },
          ) ?? `You don't have a Mentor yet`;

        const emptyStateDescriptions = [
          interpolate(
            relationshipsBaseLanguage.trainee_empty_state?.individual
              .description,
            {
              roleSingular: programTypeText?.trainee?.singular ?? 'Mentor',
            },
          ) ?? '',
          relationshipsBaseLanguage.trainee_empty_state?.individual
            .description_2 ?? '',
        ];

        const emptyStateLeftButton = {
          label: editProfileButtonLabel,
          onClick: () =>
            navigate(`/programs/${program.metadata.id.current}/join/trainee`),
          disabled: traineeRelationshipLimitReached,
        };

        const emptyStateRightButton = {
          label:
            interpolate(
              relationshipsBaseLanguage.trainee_empty_state.individual
                ?.find_a_guide,
              {
                roleSingular: programTypeText?.guide?.singular ?? 'Mentor',
              },
            ) ?? 'Find a Mentor',
          onClick: () =>
            navigate(`/programs/${program.metadata.id.current}/join/trainee`),
          disabled: traineeRelationshipLimitReached,
        };

        traineeRelationshipsEmptyState = {
          heading: emptyStateHeading,
          descriptions: emptyStateDescriptions,
          leftButton: emptyStateLeftButton,
          rightButton: emptyStateRightButton,
        };
      }

      return {
        traineeMembershipDetails,
        traineeRelationshipRows,
        traineeRelationshipsEmptyState,
      };
    }, [
      traineeMembership,
      program,
      membershipDetailsBaseLanguage.profile,
      membershipDetailsBaseLanguage?.creation_date,
      membershipDetailsBaseLanguage.individual_membership?.trainee
        ?.guide_limit_reached,
      membershipDetailsBaseLanguage.individual_membership?.trainee
        ?.find_another_guide,
      programTypeText?.trainee?.singular,
      programTypeText?.guide?.singular,
      traineeRelationships,
      traineeRelationshipLimitReached,
      navigate,
      relationshipsBaseLanguage?.no_session_placeholder,
      relationshipsBaseLanguage.trainee_empty_state.individual?.title,
      relationshipsBaseLanguage.trainee_empty_state.individual.description,
      relationshipsBaseLanguage.trainee_empty_state.individual.description_2,
      relationshipsBaseLanguage.trainee_empty_state.individual?.find_a_guide,
      palette.success.main,
      palette.error.main,
      editProfileButtonLabel,
    ]);

  //----------GUIDE MEMBERSHIP----------

  const guideMembershipData: ProgramMembershipsGuideContent = useMemo(() => {
    //Return undefined fast if no guide membership
    if (!guideMembership) {
      return {
        guideMembershipDetails: undefined,
        guideRelationshipRows: [],
        guideRelationshipsEmptyState: undefined,
      };
    }

    //Guide membership fields

    const guideSkills = getFormattedSkills(
      'guide',
      guideMembership.skills as Partial<ISkill>[],
      program.registration?.skills,
    );

    const guideFields = getFormattedProgramFields(guideMembership, program);

    guideFields.unshift(guideSkills);

    //Guide membership details

    const guideDetailsTitle =
      interpolate(membershipDetailsBaseLanguage?.profile, {
        roleSingular: programTypeText?.guide?.singular ?? 'mentor',
      }) ?? 'Your mentor profile';

    const guideDetailsDescription =
      interpolate(membershipDetailsBaseLanguage?.creation_date, {
        date: format(new Date(guideMembership.createdAt ?? ''), 'EEE do MMM'),
      }) ?? new Date(guideMembership.createdAt ?? '');

    const hasGuideRelationships = guideRelationships.length > 0;

    const guideDetailsButton = hasGuideRelationships
      ? {
          label:
            program.program_details?.program_type?.program_type_text?.variations
              ?.individual?.program_actions?.guide
              ?.manage_guide_profile_button_label ??
            'Manage your guide profile',
          onClick: () =>
            navigate(`/programs/${program.metadata.id.current}/join/guide`),
          disabled: false,
        }
      : undefined;

    const guideChip: ChipProps = guideMembership.isPublished
      ? {
          label:
            membershipDetailsBaseLanguage.individual_membership?.guide
              .guide_profile_statuses?.listed ?? 'Listed',
          color: 'success',
        }
      : {
          label:
            membershipDetailsBaseLanguage.individual_membership?.guide
              .guide_profile_statuses?.unlisted ?? 'Hidden',
          color: 'gray',
        };

    const guideMembershipDetails: ProgramMembershipDetailsViewProps = {
      title: guideDetailsTitle,
      description: guideDetailsDescription,
      fields: guideFields,
      chip: guideChip,
      button: guideDetailsButton,
    };

    let guideRelationshipRows: IDashbordRelationshipsRow[] = [];
    let guideRelationshipsEmptyState:
      | ProgramMembershipEmptyTableViewProps
      | undefined = undefined;

    //Guide relationships
    if (hasGuideRelationships) {
      guideRelationshipRows = guideRelationships.map((relationship) => {
        const traineeProfile = relationship.traineeProfiles?.at(0);
        const traineeName =
          traineeProfile?.displayName ??
          `${traineeProfile?.firstName} ${traineeProfile?.lastName}`;
        const traineeUser = relationship.users?.find(
          (user) => user.id === traineeProfile?.userId,
        );

        const sessions = relationship.sessions ?? [];

        const pastSessions =
          sessions.length > 0
            ? sessions
                .slice()
                .filter(
                  (session) =>
                    !isAfter(new Date(session?.start ?? ''), new Date()),
                )
            : [];

        const upcomingSessions =
          sessions.length > 0
            ? sessions
                .slice()
                .filter(
                  (session) =>
                    !session.isArchived &&
                    isAfter(new Date(session?.start ?? ''), new Date()),
                )
                .sort((a, b) => {
                  return a.start && b.start && a.start < b.start ? -1 : 1;
                })
            : [];

        const nextSessionLabel =
          upcomingSessions.length === 0
            ? relationshipsBaseLanguage?.no_session_placeholder
            : format(
                new Date(upcomingSessions?.at(0)?.start ?? ''),
                'EEE, do MMM',
              ) +
              ' ' +
              format(new Date(upcomingSessions?.at(0)?.start ?? ''), 'h:mmaaa');

        return {
          onClick: () => {
            navigate(`/relationships/${relationship.id}`);
          },
          userPicture: traineeUser?.picture,
          profilePicture: traineeProfile?.picture,
          name: traineeName,
          description: guideMembership.createdAt
            ? interpolate(membershipDetailsBaseLanguage?.creation_date, {
                date: format(new Date(guideMembership.createdAt), 'd/M/yyyy'),
              })
            : '',
          numberOfSessions: pastSessions.length.toString(),
          nextSessionLabel: nextSessionLabel,
          nextSessionColor:
            upcomingSessions.length > 0
              ? palette.success.main
              : palette.error.main,
        };
      });
    } else {
      //Guide relationships empty state

      const emptyStateHeading =
        interpolate(
          relationshipsBaseLanguage.guide_empty_state.individual?.title,
          {
            roleSingular: programTypeText?.trainee?.singular ?? 'Mentee',
          },
        ) ?? `You don't have a mentee yet`;

      const emptyStateDescriptions = [
        interpolate(
          relationshipsBaseLanguage.guide_empty_state.individual?.description,
          {
            roleSingular: programTypeText?.trainee?.singular ?? 'Mentee',
          },
        ) ?? '',
        relationshipsBaseLanguage.guide_empty_state.individual?.description_2 ??
          '',
      ];

      const emptyStateLeftButton = {
        label:
          relationshipsBaseLanguage.guide_empty_state?.individual
            ?.learning_hub_button_label ?? 'View Learning Hub',
        onClick: () => navigate('/learn'),
      };

      const emptyStateRightButton = {
        label: editProfileButtonLabel,
        onClick: () =>
          navigate(`/programs/${program.metadata.id.current}/manage/guide`),
      };

      guideRelationshipsEmptyState = {
        heading: emptyStateHeading,
        descriptions: emptyStateDescriptions,
        leftButton: emptyStateLeftButton,
        rightButton: emptyStateRightButton,
      };
    }

    return {
      guideMembershipDetails,
      guideRelationshipRows,
      guideRelationshipsEmptyState,
    };
  }, [
    guideMembership,
    program,
    membershipDetailsBaseLanguage?.profile,
    membershipDetailsBaseLanguage?.creation_date,
    membershipDetailsBaseLanguage.individual_membership?.guide
      .guide_profile_statuses?.listed,
    membershipDetailsBaseLanguage.individual_membership?.guide
      .guide_profile_statuses?.unlisted,
    programTypeText?.guide?.singular,
    programTypeText?.trainee?.singular,
    guideRelationships,
    navigate,
    relationshipsBaseLanguage?.no_session_placeholder,
    relationshipsBaseLanguage.guide_empty_state.individual?.title,
    relationshipsBaseLanguage.guide_empty_state.individual?.description,
    relationshipsBaseLanguage.guide_empty_state.individual?.description_2,
    relationshipsBaseLanguage.guide_empty_state.individual
      ?.learning_hub_button_label,
    palette.success.main,
    palette.error.main,
    editProfileButtonLabel,
  ]);

  return (
    <ProgramMembershipsCard
      title={programCardTitle}
      description={programCardDescription}
      avatarSrc={programAvatar}
      relationshipsEmptyStateAvatar={relationshipsEmptyStateAvatar}
      traineeMembership={traineeMembershipData}
      guideMembership={guideMembershipData}
      relationshipsTableHeaders={relationshipsTableHeaders}
    />
  );
}
