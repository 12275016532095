import { useContext } from 'react';
import { DashboardNotificationsContext } from '../DashboardNotificationsContext';
import { ProgramCtaContainer } from '../ProgramCtaContainer';
import { IndividualProgramGuideMembershipRequiresApproval } from '../IndividualProgramGuideMembershipRequiresApproval';
import { IndividualProgramGuideMembershipPublished } from '../IndividualProgramGuideMembershipPublished';
import { IndividualProgramGuideMembershipUnpublished } from '../IndividualProgramGuideMembershipUnpublished';
import { IndividualProgramScheduleSession } from '../IndividualProgramScheduleSession';
import { NotificationHighlightsContainer } from '../NotificationHighlightsContainer';

export function IndividualProgramGuide() {
  const {
    membership,
    hasMemberships,
    hasRelationships,
    hasSessions,
    hasAttendedSessions,
    hasCompletedOnboarding,
  } = useContext(DashboardNotificationsContext);

  if (hasCompletedOnboarding) {
    return <NotificationHighlightsContainer />;
  }

  if (!hasMemberships) {
    return <ProgramCtaContainer />;
  }

  if (!hasRelationships) {
    if (membership?.requiresApproval) {
      return <IndividualProgramGuideMembershipRequiresApproval />;
    } else if (membership?.isPublished) {
      return <IndividualProgramGuideMembershipPublished />;
    } else {
      return <IndividualProgramGuideMembershipUnpublished />;
    }
  }

  if (!hasSessions) {
    return <IndividualProgramScheduleSession />;
  }

  if (!hasAttendedSessions) {
    return <NotificationHighlightsContainer />;
  }
}
