import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';

import { AlertBox, Button, Card, Stack, Text } from '@guider-global/ui';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box as MuiBox, Skeleton as MuiSkeleton } from '@mui/material';
import { useMemberships, useRelationships } from 'hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupProgramMembershipsContainer } from '../GroupProgramMembershipsContainer';
import { IndividualProgramMembershipsContainer } from '../IndividualProgramMembershipsContainer';

export function ProgramMembershipsContainer() {
  const navigate = useNavigate();

  // Sanity Programs
  const organizationSlug = getSubDomain();
  const { getPrograms, isLoadingSanityPrograms } =
    useSanityOrganizationPrograms({
      organizationSlug,
    });
  const programs = getPrograms();

  //Relationship Profiles

  const { isLoadingRelationships } = useRelationships({});

  // Sanity Base Language
  const { getBaseLanguage, isLoadingSanityBaseLanguage } =
    useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // Membership Summaries
  const { memberships: getMemberships, isLoadingMemberships } = useMemberships(
    {},
  );
  const memberships = getMemberships();

  const isLoading =
    isLoadingMemberships() ||
    isLoadingSanityBaseLanguage() ||
    isLoadingSanityPrograms() ||
    isLoadingRelationships();

  const programData = useMemo(
    () =>
      programs
        .filter((program) => {
          return memberships.some(
            (membership) =>
              membership.programSlug === program.metadata.id.current,
          );
        })
        .map((program) => {
          const variation = program.program_details?.program_variation;
          return {
            program,
            variation,
          };
        }),
    [programs, memberships],
  );

  return (
    <Stack direction="column" spacing={0}>
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          alignItems: { xs: 'flex-start', md: 'center' },
          px: { xs: 2, md: 0 },
        }}
      >
        <Text
          variant="h4"
          text={baseLanguage.dashboard.program_memberships.title}
          align="center"
          sx={{ m: 0 }}
        />
        <Button
          label={
            baseLanguage.dashboard.program_memberships
              .view_all_programs_button_label
          }
          variant="text"
          color="info"
          startIcon={<FormatListBulletedIcon />}
          onClick={() => navigate('/programs')}
        />
      </MuiBox>
      {isLoading ? (
        <MuiSkeleton
          variant="rounded"
          sx={{ width: '100%', height: { xs: '200px', md: '550px' } }}
        />
      ) : (
        <Stack direction="column" spacing={2}>
          {programData.map((program) =>
            program.variation === 'group' ? (
              <GroupProgramMembershipsContainer
                key={program.program._id}
                program={program.program}
              />
            ) : (
              <IndividualProgramMembershipsContainer
                key={program.program._id}
                program={program.program}
              />
            ),
          )}
          {programData.length === 0 && (
            <Card>
              <AlertBox
                variant="iconWithBackground"
                title={
                  baseLanguage.dashboard.program_memberships.empty_state.title
                }
                description={
                  baseLanguage.dashboard.program_memberships.empty_state
                    .description
                }
                sx={{ my: '50px' }}
              />
            </Card>
          )}
        </Stack>
      )}
    </Stack>
  );
}
