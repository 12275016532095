import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { Box as MuiBox, useMediaQuery, useTheme } from '@mui/material';
import { Button, ResourceCard, Stack, Text } from '@guider-global/ui';
import ManageSearch from '@mui/icons-material/ManageSearch';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export function DashboardResourcesContainer() {
  //organization
  const organizationSlug = getSubDomain();
  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const organization = getOrganization();
  const learningHubLayoutItems = organization?.learning_hub?.layout;
  const organizationTopResources = organization?.dashboard?.top_resources;

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  //baseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({ getSilently: false });
  const baseLanguage = getBaseLanguage();
  const resourcesBaseLanguage = baseLanguage?.dashboard?.resources;
  const buttonLabels = baseLanguage?.dashboard?.resources?.content_type_actions;
  const resourceTypeLabels = baseLanguage?.learning_hub?.content_types;

  const navigate = useNavigate();

  const resources = useMemo(() => {
    if (
      organizationTopResources?.content_selection_type === 'custom' &&
      organizationTopResources?.content_selection
    ) {
      return organizationTopResources?.content_selection;
    } else {
      if (!learningHubLayoutItems) return [];
      return learningHubLayoutItems
        .flatMap((learningHubLayoutItem) => {
          if (learningHubLayoutItem._type === 'learningHubCallout') {
            return [];
          } else {
            return learningHubLayoutItem?.tile_group_content_group?.content
              ?.content_references;
          }
        })
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
    }
  }, [organizationTopResources, learningHubLayoutItems]);

  const resourcesData = useMemo(
    () =>
      resources.map((resource) => {
        const slug = resource.metadata.slug.current;
        const imageSrc = buildSanityImageUrl({
          source: resource?.content?.image?.asset,
          width: 800,
        });
        let onClick = () => navigate(`/learn/${slug}`);
        const title = resource?.content?.title ?? '...';
        const description = resource?.content?.excerpt;
        const resourceType = resource?.metadata?.type;
        let buttonLabel = '';
        let mediaLabel = '';
        if (resourceType === 'article') {
          buttonLabel = buttonLabels?.read_article_button_label;
          mediaLabel = resourceTypeLabels?.article;
        }
        if (resourceType === 'audio') {
          buttonLabel = buttonLabels?.listen_to_audio_button_label;
          mediaLabel = resourceTypeLabels?.audio;
        }
        if (resourceType === 'link') {
          buttonLabel = buttonLabels?.visit_website_button_label;
          mediaLabel = resourceTypeLabels?.external_link;
          onClick = () =>
            window.open(
              resource?.content?.link,
              '_blank',
              'noopener,noreferrer',
            );
        }
        if (resourceType === 'pdf') {
          buttonLabel = buttonLabels?.read_pdf_button_label;
          mediaLabel = resourceTypeLabels?.embedded_pdf;
        }
        if (resourceType === 'video') {
          buttonLabel = buttonLabels?.watch_video_button_label;
          mediaLabel = resource?.content?.video_duration
            ? `${resourceTypeLabels?.video} • ${resource?.content?.video_duration}`
            : resourceTypeLabels?.video;
        }
        return {
          slug,
          title,
          description,
          buttonLabel,
          mediaLabel,
          imageSrc,
          onClick,
        };
      }),
    [
      buttonLabels?.listen_to_audio_button_label,
      buttonLabels?.read_article_button_label,
      buttonLabels?.read_pdf_button_label,
      buttonLabels?.visit_website_button_label,
      buttonLabels?.watch_video_button_label,
      navigate,
      resourceTypeLabels?.article,
      resourceTypeLabels?.audio,
      resourceTypeLabels?.embedded_pdf,
      resourceTypeLabels?.external_link,
      resourceTypeLabels?.video,
      resources,
    ],
  );

  return (
    <MuiBox
      sx={{ display: 'flex', flexDirection: 'column', px: { xs: 2, md: 0 } }}
    >
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          alignItems: { xs: 'flex-start', md: 'center' },
        }}
      >
        <Text
          variant="h4"
          text={resourcesBaseLanguage.title}
          align="center"
          sx={{ m: 0 }}
        />
        <Button
          label={resourcesBaseLanguage.view_learning_hub_button_label}
          variant="text"
          color="info"
          startIcon={<ManageSearch />}
          onClick={() => navigate('/learn')}
        />
      </MuiBox>
      <Stack direction={isMobile ? 'column' : 'row'} width="100%" spacing={2}>
        {resourcesData.map((resource) => (
          <ResourceCard
            key={resource.slug}
            imageSrc={resource.imageSrc}
            title={resource.title}
            description={resource.description}
            mediaLabel={resource.mediaLabel}
            buttons={[
              {
                label: resource.buttonLabel,
                variant: 'contained',
                color: 'info',
                key: `resource-button-${resource.slug}`,
                onClick: resource.onClick,
              },
            ]}
            variant="elevation"
            sx={{ boxShadow: '0px 8px 16px 0px #00000026', flex: '1 1 0' }}
          />
        ))}
      </Stack>
    </MuiBox>
  );
}
